import moment from 'moment';

export const DATE_SERVER_FORMAT = 'YYYY-MM-DD';
export const DATE_COMPACT = 'DD/MM/YYYY';
export const DATE_FRIENDLY = 'DD MMM YYYY';
export const DATE_LONG = 'DD MMMM YYYY';
export const DAY_OF_WEEK = 'dddd';
export const DATE_CARDINAL = 'Do';

export const isInFuture = (date?: string) => {
    if (!date) {
        return false;
    }

    return moment(date, DATE_SERVER_FORMAT).isAfter(moment());
};
