import { Microform } from './setupMicroForm';

export default ({
    expiry,
    setFormError,
    setCardNumberError,
    setSecurityCodeError,
    microForm,
}: {
    expiry: string;
    setFormError: (error: string | null) => void;
    setCardNumberError: (error: string | null) => void;
    setSecurityCodeError: (error: string | null) => void;
    microForm: Microform;
}): Promise<string> => {
    setFormError(null);

    const options = {
        expirationMonth: expiry.split('/')[0],
        expirationYear: '20' + expiry.split('/')[1],
    };

    return new Promise((resolve, reject) => {
        console.log('creating microform token');
        microForm.createToken(options, (err, token) => {
            if (err) {
                setFormError(err.message);
                err.details?.forEach((detail: { location: string }) => {
                    if (detail.location === 'number') {
                        setCardNumberError('Please enter a valid card number');
                    } else if (detail.location === 'securityCode') {
                        setSecurityCodeError('Please enter a valid security code');
                    }
                });
                return reject(new Error('Invalid form data'));
            } else {
                return resolve(JSON.stringify(token));
            }
        });
    });
};
