import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Alert, Box, Button, Typography } from '@mui/material';
import { round } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import {
    AnnotatedTermPaymentMethod,
    CalculatedOn,
    CardPaymentProcessor,
    Charge,
    ChargeType,
    InvoiceSummary,
} from '../../../apis/invoices';
import { PaymentMethod, PaymentMethodType, fetchPaymentSession } from '../../../apis/paymentMethodApi';
import { LoadingButton } from '../../../components/LoadingButton';
import PageLoading from '../../../components/PageLoading';
import StepCard from '../../../components/StepCard';
import { isSuccess } from '../../../hooks/useFetch';
import { RootState } from '../../../store/Store';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { getClassCodeDescription } from '../../../util/invoiceUtils';
import PaymentMethodDetails from '../PaymentMethodDetails';
import PaymentRadioGroup, { InvoicePaymentMethod, PaymentFormFields } from './PaymentRadioGroup';
import useTokenization from './WindcavePayment/useTokenization';
import getFormSchema from './formSchema';
import submitCardPaymentMethod from './submitCardPaymentMethod';
import submitDDPaymentMethod from './submitDDPaymentMethod';

export type UpdateStepProps = {
    cancel: () => void;
    setNewPaymentMethod: (method: PaymentMethod, newCharge: number) => void;
    sessionId?: string | null;
};

type Props = UpdateStepProps & {
    data: AnnotatedTermPaymentMethod[];
};

export default ({ cancel, data: availablePaymentMethods, setNewPaymentMethod, sessionId }: Props) => {
    const [loadingRes, setLoadingRes] = useState(false);
    const [loadingPreSubmit, setLoadingPreSubmit] = useState(false);
    const preSubmitRef = useRef<{ preSubmitHelper: () => Promise<string> } | undefined>();
    const pollingCount = useRef(0);
    const newCardPaymentMethod = useRef<null | PaymentMethod>(null);
    const [formError, setFormError] = useState<string | null>(null);

    const { value: invoice, paymentMethodState } = useAppSelector((root: RootState) => root.persistedInvoiceReducer);
    const firstPolicyClasscode = getClassCodeDescription(invoice.portfolio.policies[0]);
    const { charges, paymentMethods } = getChargesAndPaymentMethods(availablePaymentMethods, invoice);

    const {
        control,
        setError,
        setValue,
        clearErrors,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<PaymentFormFields>({
        resolver: yupResolver(getFormSchema(paymentMethods.cardPaymentMethod)) as Resolver<PaymentFormFields>,
        defaultValues: {
            paymentMethod: InvoicePaymentMethod.NOT_SET,
        },
    });

    useEffect(() => {
        if (sessionId != null) {
            setLoadingRes(true);
            setValue('paymentMethod', InvoicePaymentMethod.CREDIT_CARD);
        } else {
            setLoadingRes(false);
            setValue('paymentMethod', InvoicePaymentMethod.NOT_SET);
        }
    }, [sessionId]);

    const handleSetPaymentMethod = (newCharge: number) => {
        return (method: PaymentMethod) => setNewPaymentMethod(method, newCharge);
    };
    useTokenization({ sessionId, setPaymentMethod: handleSetPaymentMethod(charges.card ?? 0), setError: setFormError });

    const submitHandler: SubmitHandler<PaymentFormFields> = (data) => {
        if (data.paymentMethod === InvoicePaymentMethod.CREDIT_CARD) {
            if (!paymentMethods.cardPaymentMethod) {
                return setFormError('Cannot pay via card');
            }

            if (paymentMethods.cardPaymentMethod.cardPaymentProcessor === CardPaymentProcessor.CYBERSOURCE) {
                if (!preSubmitRef.current) {
                    return setFormError('Invalid form state. Please try again.');
                }

                submitCardPaymentMethod({
                    setLoadingPreSubmit,
                    setLoadingRes,
                    preSubmitHelper: preSubmitRef.current.preSubmitHelper,
                    invoiceUuid: invoice.uuid,
                    newCardPaymentMethod,
                    pollingCount,
                    setFormError,
                    setNewPaymentMethod: handleSetPaymentMethod(charges.card ?? 0),
                    paymentGatewayIdentifier: paymentMethods.cardPaymentMethod.paymentGatewayIdentifier,
                    cardPaymentProcessor: paymentMethods.cardPaymentMethod.cardPaymentProcessor,
                });
            } else if (paymentMethods.cardPaymentMethod.cardPaymentProcessor === CardPaymentProcessor.WINDCAVE) {
                fetchPaymentSession(
                    invoice.uuid,
                    paymentMethods.cardPaymentMethod.paymentGatewayIdentifier,
                    paymentMethods.cardPaymentMethod.cardPaymentProcessor
                ).then((url) => {
                    window.location.href = url;
                });
            }
        } else {
            if (!paymentMethods.directDebitPaymentMethod) {
                return setFormError('Cannot pay via direct debit');
            }

            submitDDPaymentMethod({
                data,
                invoiceUuid: invoice.uuid,
                setLoadingRes,
                setError,
                setNewPaymentMethod: handleSetPaymentMethod(charges.directDebit ?? 0),
                setFormError,
            });
        }
    };

    const displayCurrentPaymentMethod =
        isSuccess(paymentMethodState) &&
        availablePaymentMethods
            .map(({ paymentGatewayIdentifier }) => paymentGatewayIdentifier)
            .includes(paymentMethodState.value.paymentGatewayIdentifier);

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <StepCard>
                <Box>
                    <Typography variant='h5' component='h2'>
                        Change payment method
                    </Typography>
                    <Typography variant='body2'>
                        Insurance #{invoice.number}
                        {firstPolicyClasscode ? ` (${firstPolicyClasscode})` : ''}
                    </Typography>
                </Box>

                {displayCurrentPaymentMethod && (
                    <Box>
                        <Typography variant='h6' component='h3'>
                            Current payment method
                        </Typography>
                        <Typography variant='subtitle2' component='p'>
                            {paymentMethodState.value.type === PaymentMethodType.DIRECT_DEBIT ? 'Direct debit' : 'Card'}
                        </Typography>
                        <PaymentMethodDetails data={paymentMethodState.value} reversed />
                    </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant='h6' component='h3'>
                        New payment method
                    </Typography>
                    {formError && <Alert severity='error'>{formError}</Alert>}

                    {loadingRes ? (
                        <PageLoading />
                    ) : (
                        <PaymentRadioGroup
                            control={control}
                            setError={setError}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            getValues={getValues}
                            errors={errors}
                            charges={charges}
                            paymentMethods={paymentMethods}
                            preSubmitRef={preSubmitRef}
                        />
                    )}
                </Box>

                <Box sx={{ display: 'flex', gap: 1 }}>
                    <LoadingButton
                        loading={loadingPreSubmit}
                        disabled={loadingPreSubmit || loadingRes}
                        variant='contained'
                        color='primary'
                        type='submit'
                        size='large'
                    >
                        Confirm
                    </LoadingButton>
                    <Button
                        disabled={loadingPreSubmit || loadingRes}
                        onClick={cancel}
                        variant='text'
                        color='primary'
                        size='large'
                    >
                        Cancel
                    </Button>
                </Box>
            </StepCard>
        </form>
    );
};

export type Charges = {
    card?: number;
    directDebit?: number;
};

export type PaymentMethods = {
    cardPaymentMethod?: AnnotatedTermPaymentMethod;
    directDebitPaymentMethod?: AnnotatedTermPaymentMethod;
};

export type ChargesAndPaymentMethods = {
    charges: Charges;
    paymentMethods: PaymentMethods;
};

const getChargesAndPaymentMethods = (
    availablePaymentMethods: AnnotatedTermPaymentMethod[],
    invoice: InvoiceSummary
): ChargesAndPaymentMethods => {
    const charges: Charges = {};
    const cardPaymentMethod = availablePaymentMethods.find(
        (method) => method.termPaymentMethod.paymentMethodType === PaymentMethodType.CREDIT_CARD
    );
    if (cardPaymentMethod) {
        charges.card = calculateChargeAmount(cardPaymentMethod.termPaymentMethod.paymentMethodCharges, invoice);
    }

    const directDebitPaymentMethod = availablePaymentMethods.find(
        (method) => method.termPaymentMethod.paymentMethodType === PaymentMethodType.DIRECT_DEBIT
    );
    if (directDebitPaymentMethod) {
        charges.directDebit = calculateChargeAmount(
            directDebitPaymentMethod.termPaymentMethod.paymentMethodCharges,
            invoice
        );
    }

    const paymentMethods: PaymentMethods = { cardPaymentMethod, directDebitPaymentMethod };
    return { charges, paymentMethods };
};

const calculateChargeAmount = (charges: Charge[], invoice: InvoiceSummary): number => {
    if (invoice.nextPayment != null) {
        return calculateChargeAmountOnNextPayment(charges, invoice.nextPayment.instalmentAmount);
    } else {
        return charges.reduce((a, charge) => a + charge.amount, 0);
    }
};

const calculateChargeAmountOnNextPayment = (charges: Charge[], amount: number) => {
    if (charges != null && charges.length > 0) {
        return charges
            .filter((charge) => charge.type === ChargeType.TRANSACTION_FEE)
            .map((charge) => {
                if (charge.chargeValue == null) {
                    return 0;
                }
                return calculateCharge(amount)(charge);
            })
            .reduce((a, v) => a + v, 0);
    } else {
        return 0;
    }
};

const calculateCharge = (amount: number) => {
    return (charge: Charge) =>
        charge.calculatedOn === CalculatedOn.FIXED ? charge.chargeValue : round(amount * (charge.chargeValue / 100), 2);
};
