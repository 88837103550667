import { createSlice } from '@reduxjs/toolkit';

export type CybersourceAction = {
    type: string;
    payload: CYBERSOURCE_STATE;
};

export enum CYBERSOURCE_STATE {
    PRE_LOAD,
    LOADING,
    LOADED,
}

export const cybersourceSlice = createSlice({
    name: 'cybersource',
    initialState: {
        value: CYBERSOURCE_STATE.PRE_LOAD,
    },
    reducers: {
        setCybersourceState: (state: { value: CYBERSOURCE_STATE }, action: CybersourceAction) => {
            state.value = action.payload;
        },
    },
});

export const { setCybersourceState } = cybersourceSlice.actions;

export default cybersourceSlice.reducer;
