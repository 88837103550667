import { createSlice } from '@reduxjs/toolkit';
import { AnnotatedTermPaymentMethod, InvoiceSummary } from '../../apis/invoices';
import { PaymentMethod } from '../../apis/paymentMethodApi';
import { FetchState, FetchStateType } from '../../hooks/useFetch';

export type InvoiceAction<T> = {
    type: string;
    payload: T;
};

const initialPaymentMethodState = {
    type: FetchStateType.PENDING,
} as FetchState<PaymentMethod>;

const initialAvailablePaymentMethodsState = {
    type: FetchStateType.PENDING,
} as FetchState<AnnotatedTermPaymentMethod[]>;

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: {
        value: {} as InvoiceSummary,
        paymentMethodState: { ...initialPaymentMethodState },
        availablePaymentMethodsState: { ...initialAvailablePaymentMethodsState },
    },
    reducers: {
        setInvoice: (state, action: InvoiceAction<InvoiceSummary>) => {
            state.value = action.payload;
        },
        setPaymentMethodState: (state, action: InvoiceAction<FetchState<PaymentMethod>>) => {
            state.paymentMethodState = action.payload;
        },
        setAvailablePaymentMethodsState: (state, action: InvoiceAction<FetchState<AnnotatedTermPaymentMethod[]>>) => {
            state.availablePaymentMethodsState = action.payload;
        },
        clearInvoice: (state) => {
            state.value = {} as InvoiceSummary;
            state.paymentMethodState = { ...initialPaymentMethodState };
            state.availablePaymentMethodsState = { ...initialAvailablePaymentMethodsState };
        },
    },
});

export const { setInvoice, setPaymentMethodState, setAvailablePaymentMethodsState, clearInvoice } =
    invoiceSlice.actions;

export default invoiceSlice.reducer;
