import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchInvoice,
    fetchLoan,
    fetchTermPaymentMethods,
    InvoiceStatus,
    InvoiceSummary,
    MigrationStatus,
} from '../../apis/invoices';
import { fetchCurrentPaymentMethod } from '../../apis/paymentMethodApi';
import MigratedInvoiceAlert from '../../components/MigratedInvoiceAlert';
import PageLoading from '../../components/PageLoading';
import { isSuccess, simpleStoreFetch } from '../../hooks/useFetch';
import { RootState } from '../../store/Store';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setAvailablePaymentMethodsState, setInvoice, setPaymentMethodState } from '../../store/reducer/InvoiceReducer';
import { setLoanState } from '../../store/reducer/LoanReducer';
import { isInFuture } from '../../util/dateUtils';
import CancellationScheduledAlert from './CancellationScheduledAlert';
import CancelledAlert from './CancelledAlert';
import Details from './Details';
import OverdueAlert from './OnDemandPayment/OverdueAlert';
import PaymentRequiredAlert from './OnDemandPayment/PaymentRequiredAlert';
import PendingCancellationAlert from './PendingCancellationAlert';

export default () => {
    const navigate = useNavigate();
    const { uuid } = useParams<{ uuid?: string }>();
    const { value: invoice, paymentMethodState } = useAppSelector((state: RootState) => state.persistedInvoiceReducer);
    const { value: loanState } = useAppSelector((state: RootState) => state.LoanReducer);
    const [loading, setLoading] = useState(invoice == null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (invoice?.uuid == null || uuid == null) {
            return;
        }

        if (!isSuccess(loanState) || loanState.value.invoiceIdentifier !== uuid) {
            simpleStoreFetch({ dispatch, setState: setLoanState, fetch: () => fetchLoan(uuid) });
        }

        if (!isSuccess(paymentMethodState) || paymentMethodState.value.invoiceIdentifier !== uuid) {
            simpleStoreFetch({
                dispatch,
                setState: setPaymentMethodState,
                fetch: () => fetchCurrentPaymentMethod(uuid),
            });

            simpleStoreFetch({
                dispatch,
                setState: setAvailablePaymentMethodsState,
                fetch: () => fetchTermPaymentMethods(uuid),
            });
        }
    }, [invoice]);

    useEffect(() => {
        if (invoice?.uuid !== uuid && uuid != null && !loading) {
            setLoading(true);
            fetchInvoice(uuid)
                .then((invoiceSummary) => {
                    dispatch(setInvoice(invoiceSummary));
                })
                .catch(() => {
                    navigate('/not-found');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [uuid]);

    if (!uuid || loading || invoice?.uuid == null) {
        return <PageLoading />;
    }

    const alerts = getAlerts(invoice);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Typography variant='h3' component='h1'>
                Insurance details
            </Typography>

            {isInFuture(invoice.cancellationDate) && (
                <Box mb={3}>
                    <CancellationScheduledAlert invoice={invoice} />
                </Box>
            )}
            {alerts}
            <Details />
        </Box>
    );
};

const getAlerts = (invoice: InvoiceSummary) => {
    const invoiceStatusAlert = getAlert(invoice);

    if (invoice.migrationStatus === MigrationStatus.PARTIAL) {
        return (
            <>
                <MigratedInvoiceAlert />
                {invoiceStatusAlert}
            </>
        );
    }
    return invoiceStatusAlert;
};

const getAlert = (invoice: InvoiceSummary) => {
    switch (invoice.status) {
        case InvoiceStatus.PENDING_CANCELLATION:
            return <PendingCancellationAlert />;

        case InvoiceStatus.CANCELLED:
            return <CancelledAlert invoice={invoice} />;

        case InvoiceStatus.OVERDUE:
            return <OverdueAlert invoice={invoice} />;

        case InvoiceStatus.PARTIALLY_PAID:
            return <PaymentRequiredAlert invoice={invoice} />;

        default:
            return null;
    }
};
