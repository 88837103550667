import {
    Accordion,
    AccordionDetails,
    AccordionSummary as BaseAccordionSummary,
    Box,
    FormControl,
    FormHelperText,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';
import {
    Control,
    Controller,
    FieldErrors,
    UseFormClearErrors,
    UseFormGetValues,
    UseFormSetError,
    UseFormSetValue,
} from 'react-hook-form';
import { RootState } from '../../../store/Store';
import { useAppSelector } from '../../../store/reducer/Hooks';
import BankAccount from './BankAccount';
import CybersourcePayment from './CybersourcePayment';

// @ts-ignore
import visaLogo from '../../../images/visa.svg';
// @ts-ignore
import mcLogo from '../../../images/mastercard.svg';
import { Charges, PaymentMethods } from './UpdateStep';
import { CardPaymentProcessor } from '../../../apis/invoices';
import WindcavePayment from './WindcavePayment';

export enum InvoicePaymentMethod {
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    CREDIT_CARD = 'CREDIT_CARD',
    NOT_SET = 'NOT_SET',
}

export type PaymentFormFields = {
    expiry: string;
    accountHolder: string;
    bankAccountNumber: string;
    confirmAuthority: boolean;
    paymentMethod: InvoicePaymentMethod;
};

type Props = {
    charges: Charges;
    paymentMethods: PaymentMethods;
    control: Control<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
    setError: UseFormSetError<PaymentFormFields>;
    setValue: UseFormSetValue<PaymentFormFields>;
    clearErrors: UseFormClearErrors<PaymentFormFields>;
    getValues: UseFormGetValues<PaymentFormFields>;
    preSubmitRef: React.MutableRefObject<{ preSubmitHelper: () => Promise<string> } | undefined>;
    cardPaymentError?: string;
};

export default ({
    control,
    errors,
    charges,
    paymentMethods,
    getValues,
    preSubmitRef,
    cardPaymentError,
    setError,
    clearErrors,
}: Props) => {
    const invoice = useAppSelector((root: RootState) => root.persistedInvoiceReducer).value;
    const instalmentAmount = invoice.nextPayment?.instalmentAmount ?? 0;

    return (
        <Box>
            <FormControl fullWidth required sx={{ mb: 2 }}>
                <Controller
                    name='paymentMethod'
                    control={control}
                    render={({ field }) => (
                        <RadioGroup
                            id='paymentMethod'
                            {...field}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                if (value) {
                                    event.target.value = value;
                                    field.onChange(event);
                                }
                            }}
                        >
                            {charges.card != null && paymentMethods.cardPaymentMethod != null && (
                                <Accordion
                                    expanded={getValues('paymentMethod') === InvoicePaymentMethod.CREDIT_CARD}
                                    disableGutters={true}
                                >
                                    <AccordionSummary>
                                        <label
                                            htmlFor='ccOption'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <Radio id='ccOption' value={InvoicePaymentMethod.CREDIT_CARD} />
                                                <Typography>Credit card</Typography>
                                            </span>
                                            <span style={{ height: '100%', display: 'flex', marginRight: '8px' }}>
                                                <img src={visaLogo} alt='Visa logo' width='38px' />
                                                <img
                                                    src={mcLogo}
                                                    style={{ marginLeft: 1, marginRight: 1 }}
                                                    alt='Mastercard logo'
                                                    width='38px'
                                                />
                                            </span>
                                        </label>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ bgcolor: grey['200'] }}>
                                        {paymentMethods.cardPaymentMethod.cardPaymentProcessor ===
                                        CardPaymentProcessor.CYBERSOURCE ? (
                                            <CybersourcePayment
                                                instalmentAmount={instalmentAmount + charges.card}
                                                control={control}
                                                errors={errors}
                                                getValues={getValues}
                                                preSubmitRef={preSubmitRef}
                                                cardPaymentError={cardPaymentError}
                                                paymentGatewayIdentifier={
                                                    paymentMethods.cardPaymentMethod.paymentGatewayIdentifier
                                                }
                                                cardPaymentProcessor={
                                                    paymentMethods.cardPaymentMethod.cardPaymentProcessor
                                                }
                                                invoice={invoice}
                                            />
                                        ) : (
                                            <WindcavePayment />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            )}
                            {charges.directDebit != null && (
                                <Accordion
                                    expanded={getValues('paymentMethod') === InvoicePaymentMethod.DIRECT_DEBIT}
                                    disableGutters={true}
                                >
                                    <AccordionSummary>
                                        <label
                                            htmlFor='ddOption'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <Radio id='ddOption' value={InvoicePaymentMethod.DIRECT_DEBIT} />
                                                <Typography>Direct debit</Typography>
                                            </span>
                                        </label>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ bgcolor: grey['200'] }}>
                                        <BankAccount
                                            directDebitAuthorityNumber={
                                                paymentMethods.directDebitPaymentMethod?.directDebitAuthorityNumber
                                            }
                                            instalmentAmount={instalmentAmount + charges.directDebit}
                                            control={control}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            errors={errors}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </RadioGroup>
                    )}
                />
            </FormControl>
            {errors.paymentMethod?.message && (
                <FormHelperText error={!!errors?.paymentMethod}>{errors?.paymentMethod?.message}</FormHelperText>
            )}
        </Box>
    );
};

const AccordionSummary = styled(BaseAccordionSummary)(() => ({
    padding: 0,
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
}));
