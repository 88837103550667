import { Button, ButtonProps, IconButton } from '@mui/material';
import { useAppSelector } from '../store/reducer/Hooks';
import { RootState } from '../store/Store';
import { BREAKPOINT } from '../store/reducer/BreakpointReducer';

type Props = ButtonProps & {
    icon: React.ReactNode;
};

export default (props: Props) => {
    const { up } = useAppSelector((state: RootState) => state.BreakpointReducer);

    return up(BREAKPOINT.sm) ? (
        <Button
            variant='outlined'
            color='primary'
            data-testid='large-res-button'
            sx={{ height: '30px' }}
            startIcon={props.icon}
            {...props}
        >
            {props.children}
        </Button>
    ) : (
        <IconButton color='primary' data-testid='small-res-button' {...props}>
            {props.icon}
        </IconButton>
    );
};
