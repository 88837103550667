import { useEffect, useRef } from 'react';
import {
    CardPaymentMethod,
    PaymentMethod,
    PaymentMethodStatus,
    completeTokenSession,
    fetchPaymentMethodStatus,
} from '../../../../apis/paymentMethodApi';
import { useSearchParams } from 'react-router-dom';

type Props = {
    sessionId?: string | null;
    setPaymentMethod: (method: PaymentMethod) => void;
    setError: (error: string) => void;
};

export default function useTokenization({ sessionId, setPaymentMethod, setError }: Readonly<Props>) {
    const polls = useRef(1);
    const pollingRef = useRef<number>();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (sessionId == null) {
            return;
        }

        completeTokenSession(sessionId)
            .then(({ paymentMethodIdentifier }) => {
                pollingRef.current = window.setInterval(pollTokenizationRes(paymentMethodIdentifier), POLLING_TIME);
            })
            .catch((e) => {
                console.log(e);
                setError('Something went wrong, please try again.');
                stopPolling();
            });

        return stopPolling;
    }, [sessionId]);

    const stopPolling = () => {
        if (pollingRef.current != null) {
            clearInterval(pollingRef.current);
            pollingRef.current = undefined;
        }

        searchParams.delete('sessionId');
        setSearchParams(searchParams);
    };

    const pollTokenizationRes = (paymentMethodIdentifier: string) => () => {
        if (polls.current > MAX_POLLS) {
            setError(AUTH_ERROR);
            stopPolling();
        }

        polls.current = polls.current + 1;
        fetchPaymentMethodStatus(paymentMethodIdentifier)
            .then((paymentMethod) => {
                if (paymentMethod.status === PaymentMethodStatus.VERIFIED) {
                    setPaymentMethod(paymentMethod);
                    stopPolling();
                } else if (paymentMethod.status === PaymentMethodStatus.VOID) {
                    setError(
                        `Authorisation failed: ${(paymentMethod as CardPaymentMethod).dishonourMessage}. Please try again with a different card.`
                    );
                    stopPolling();
                }
            })
            .catch((e) => {
                console.log(e);
                setError(AUTH_ERROR);
                stopPolling();
            });
    };
}

const AUTH_ERROR = 'Authorisation failed. Please try again with a different card.';
const POLLING_TIME = 1_000;
const MAX_POLLS = 30;
