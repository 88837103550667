import { UseFormSetError } from 'react-hook-form';
import { BankAccount, PaymentMethod, authoriseNewDirectDebit } from '../../../apis/paymentMethodApi';
import { PaymentFormFields } from './PaymentRadioGroup';

export default async ({
    data,
    invoiceUuid,
    setLoadingRes,
    setError,
    setNewPaymentMethod,
    setFormError,
}: {
    data: PaymentFormFields;
    invoiceUuid: string;
    setLoadingRes: (loading: boolean) => void;
    setError: UseFormSetError<PaymentFormFields>;
    setNewPaymentMethod: (paymentMethod: PaymentMethod) => void;
    setFormError: (error: string | null) => void;
}) => {
    setFormError(null);
    setLoadingRes(true);
    await authoriseNewDirectDebit(invoiceUuid, { ...data } as BankAccount)
        .then((paymentMethodResp) => {
            setNewPaymentMethod(paymentMethodResp);
        })
        .catch((e) => {
            if (e.response && e.response.status === 400) {
                setError('bankAccountNumber', { message: 'Invalid bank/branch' });
            }
            setLoadingRes(false);
            setFormError('Something went wrong, please try again.');
        });
};
