import { Auth } from 'aws-amplify';
import { AxiosRequestConfig } from 'axios';

export const axiosRequestInterceptor = async (config: AxiosRequestConfig) => {
    const { signInUserSession } = await Auth.currentAuthenticatedUser();

    const token = signInUserSession.getIdToken().getJwtToken();
    if (token) {
        config.headers!.Authorization = 'Bearer ' + token;
    }
    return config;
};
