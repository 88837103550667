import { TextField, Button, Alert } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Typography } from '@mui/material';
import { AuthError, ErrorType } from './EnterCodeStep';
import SimfuniAuthCard from './SimfuniAuthCard';
import { includes } from 'lodash';
import { AxiosError } from 'axios';

export type Props = {
    stepComplete: () => void;
    setCognitoUser: (username: CognitoUser) => void;
};

export default (props: Props) => {
    const [username, setUsername] = useState('');
    const [isLoadingState, setIsLoadingState] = useState(false);
    const [error, setError] = useState<AuthError | null>(null);

    const submitHandler = (e: SyntheticEvent) => {
        setIsLoadingState(true);

        Auth.signIn(username)
            .then((user) => {
                props.setCognitoUser(user);
                props.stepComplete();
            })
            .catch(handleError)
            .finally(() => setIsLoadingState(false));

        e.preventDefault();
    };

    const handleError = (error: AxiosError) => {
        if (error.code === 'UserLambdaValidationException' && includes(error.message, 'RATE_LIMITED')) {
            setError({
                type: ErrorType.ERROR,
                message: <>Attempt limit exceeded, please try after some time.</>,
            });
        }
    };

    const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUsername(event.currentTarget.value);
    };

    return (
        <SimfuniAuthCard>
            <form onSubmit={submitHandler} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography variant='h3' component='h1'>
                    Enter email address
                </Typography>
                <Typography>We'll send you a verification code to log in</Typography>
                <TextField
                    data-testid='emailInput'
                    label='Email'
                    onChange={handleUsernameChange}
                    isRequired
                    type='email'
                />
                {error && (
                    <Alert data-testid='error' isDismissible hasIcon variation={error.type}>
                        {error.message}
                    </Alert>
                )}
                <Button variation='primary' type='submit' isFullWidth isLoading={isLoadingState} loadingText='Continue'>
                    Continue
                </Button>
            </form>
        </SimfuniAuthCard>
    );
};
