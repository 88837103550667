import { Alert, Box, FormControl, FormHelperText, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useImperativeHandle, useState } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { CardPaymentProcessor, InvoiceSummary } from '../../../../apis/invoices';
import { fetchPaymentSession } from '../../../../apis/paymentMethodApi';
import PageLoading from '../../../../components/PageLoading';
import { isSuccess, useFetch } from '../../../../hooks/useFetch';
import { RootState } from '../../../../store/Store';
import { CYBERSOURCE_STATE, setCybersourceState } from '../../../../store/reducer/CybersourceReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/reducer/Hooks';
import { PaymentFormFields } from '../PaymentRadioGroup';
import addScript from './addScript';
import createToken from './createToken';
import setupMicroForm, { Microform } from './setupMicroForm';

type Props = {
    instalmentAmount: number;
    control: Control<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
    getValues: UseFormGetValues<PaymentFormFields>;
    preSubmitRef: React.MutableRefObject<{ preSubmitHelper: () => Promise<string> } | undefined>;
    cardPaymentError?: string;
    paymentGatewayIdentifier: string;
    cardPaymentProcessor: CardPaymentProcessor;
    invoice: InvoiceSummary;
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

export default ({
    instalmentAmount,
    cardPaymentError,
    control,
    errors,
    getValues,
    preSubmitRef,
    paymentGatewayIdentifier,
    cardPaymentProcessor,
    invoice,
}: Props) => {
    const [microForm, setMicroForm] = useState<Microform>();
    const [cardNumberError, setCardNumberError] = useState<string | null>(null);
    const [securityCodeError, setSecurityCodeError] = useState<string | null>(null);
    const [formError, setFormError] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const cybersourceState: CYBERSOURCE_STATE = useAppSelector((state: RootState) => state.CybersourceReducer).value;
    const [loadingCybersourceInputs, setLoadingCybersourceInputs] = useState(true);
    useImperativeHandle(preSubmitRef, () => ({ preSubmitHelper }));

    const sessionState = useFetch(() =>
        fetchPaymentSession(invoice.uuid, paymentGatewayIdentifier, cardPaymentProcessor)
    );

    const setLoading = () => {
        setLoadingCybersourceInputs(true);
        dispatch(setCybersourceState(CYBERSOURCE_STATE.LOADING));
    };

    useEffect(() => {
        if (cybersourceState === CYBERSOURCE_STATE.PRE_LOAD) {
            addScript(setLoading, () => dispatch(setCybersourceState(CYBERSOURCE_STATE.LOADED)));
        } else if (cybersourceState === CYBERSOURCE_STATE.LOADED && isSuccess(sessionState)) {
            setupMicroForm({
                paymentSession: sessionState.value,
                setCardNumberError,
                setSecurityCodeError,
                setMicroForm,
                setLoadingCybersourceInputs,
            });
        }
    }, [cybersourceState, sessionState]);

    useEffect(() => {
        if (cardPaymentError) {
            setFormError(cardPaymentError);
        }
    }, [cardPaymentError]);

    const preSubmitHelper = (): Promise<string> => {
        const expiry = getValues('expiry');
        return createToken({ expiry, setFormError, setCardNumberError, setSecurityCodeError, microForm: microForm! });
    };

    return (
        <>
            {formError && (
                <Alert severity='error' sx={{ mb: 2 }}>
                    {formError}
                </Alert>
            )}

            {loadingCybersourceInputs && <PageLoading />}

            <Box
                sx={{
                    height: loadingCybersourceInputs ? 0 : '100%',
                    visibility: loadingCybersourceInputs ? 'hidden' : 'visible',
                }}
            >
                <FormControl required fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Card number</FormLabel>
                    <div className={cardNumberError != null ? 'registered-error' : ''}>
                        <div id='number-container' className='form-control'></div>
                    </div>
                    {cardNumberError != null && <FormHelperText error>{cardNumberError}</FormHelperText>}
                </FormControl>

                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <FormControl required fullWidth sx={{ mb: 2 }}>
                            <FormLabel>Expires</FormLabel>
                            <Controller
                                name='expiry'
                                control={control}
                                defaultValue={''}
                                render={({ field }) => {
                                    return (
                                        <ReactInputMask
                                            mask='99/99'
                                            alwaysShowMask={true}
                                            maskPlaceholder='MM/YY'
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                        >
                                            <TextField
                                                {...field}
                                                error={errors.expiry !== undefined}
                                                helperText={errors.expiry?.message}
                                                size='small'
                                            />
                                        </ReactInputMask>
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl required fullWidth sx={{ mb: 2 }}>
                            <FormLabel>Security Code</FormLabel>
                            <div className={securityCodeError != null ? 'registered-error' : ''}>
                                <div id='securityCode-container' className='form-control'></div>
                            </div>
                            {securityCodeError != null && <FormHelperText error>{securityCodeError}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography sx={{ mt: 1, mb: 2 }}>
                    Your next instalment amount is{' '}
                    <span style={{ display: 'inline', fontWeight: 600, fontSize: 18 }} data-testid='regularInstalment'>
                        {currencyFormatter.format(instalmentAmount)}
                    </span>
                    <span style={{ display: 'inline', fontSize: 11 }}> (includes processing fee).</span>
                </Typography>
            </Box>

            <input type='hidden' id='flexresponse' name='flexresponse' />
        </>
    );
};
