import * as yup from 'yup';
import moment from 'moment';
import { InvoicePaymentMethod } from './PaymentRadioGroup';
import { PaymentMethodType } from '../../../apis/paymentMethodApi';
import { AnnotatedTermPaymentMethod, CardPaymentProcessor } from '../../../apis/invoices';

const expiryRegex = new RegExp(/^\d{2}\/\d{2}$/);

export default function getFormSchema(cardPaymentMethod?: AnnotatedTermPaymentMethod) {
    return yup.object({
        paymentMethod: yup
            .mixed<InvoicePaymentMethod>()
            .oneOf(
                [InvoicePaymentMethod.CREDIT_CARD, InvoicePaymentMethod.DIRECT_DEBIT],
                'Payment method selection required'
            )
            .required(),
        accountHolder: yup
            .string()
            .trim()
            .when('paymentMethod', (paymentMethod, schema) => {
                if (paymentMethod === InvoicePaymentMethod.DIRECT_DEBIT) {
                    return schema.required('Account holder name required');
                }
                return schema.notRequired();
            }),
        bankAccountNumber: yup
            .string()
            .trim()
            .when('paymentMethod', (paymentMethod, schema) => {
                if (paymentMethod === InvoicePaymentMethod.DIRECT_DEBIT) {
                    return schema
                        .required('Bank account number required')
                        .matches(/^\d{2}-\d{4}-\d{7}-\d{2,3}$/, 'Bank account number format is invalid');
                }
                return schema.notRequired();
            }),
        confirmAuthority: yup.boolean().when('paymentMethod', (paymentMethod, schema) => {
            if (paymentMethod === InvoicePaymentMethod.DIRECT_DEBIT) {
                return schema.oneOf([true]).required('Confirmation of authority over bank account is required');
            }
            return schema.notRequired();
        }),
        expiry: yup
            .string()
            .trim()
            .test(
                'valid-expiry',
                () => 'Expiry date invalid',
                (value, context) => {
                    if (
                        context.parent.paymentMethod !== PaymentMethodType.CREDIT_CARD ||
                        cardPaymentMethod?.cardPaymentProcessor !== CardPaymentProcessor.CYBERSOURCE
                    ) {
                        return true;
                    }
                    if (!value) {
                        return false;
                    }
                    if (!expiryRegex.test(value)) {
                        return false;
                    }
                    const today = moment();
                    const parts = value.split('/');
                    const expiryDate = moment();
                    expiryDate.month(Number(parts[0]) - 1);
                    expiryDate.year(2000 + Number(parts[1]));

                    return today.isSameOrBefore(expiryDate, 'month');
                }
            ),
    });
}
