import theme, { applyCssVariables } from './theme';
import { ThemeProvider } from '@mui/material';
import { FetchStateType, useFetch } from '../hooks/useFetch';
import { UIConfig, getSellerUIConfig } from '../apis/seller';
import { useAppDispatch } from '../store/reducer/Hooks';
import { setSellerUIConfig } from '../store/reducer/SellerReducer';
import PageLoading from '../components/PageLoading';

type Props = {
    children: React.ReactNode;
};

export default ({ children }: Props) => {
    const dispatch = useAppDispatch();

    const uiState = useFetch(() =>
        getSellerUIConfig()
            .then((uiConfig) => {
                if (uiConfig != null) {
                    dispatch(setSellerUIConfig(uiConfig));
                }
                applyCssVariables(uiConfig);
                applyPageMeta(uiConfig);
                return uiConfig;
            })
            .catch(() => {
                applyCssVariables();
                applyPageMeta();
                return undefined;
            })
    );

    if ([FetchStateType.LOADING, FetchStateType.PENDING].includes(uiState.type)) {
        return (
            <ThemeProvider theme={theme()}>
                <PageLoading />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme(uiState.type === FetchStateType.SUCCESS ? uiState.value : undefined)}>
            {children}
        </ThemeProvider>
    );
};

const applyPageMeta = (uiConfig?: UIConfig) => {
    // favicon
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }
    link.href = !uiConfig?.favIconUrl ? '/favicon.ico' : uiConfig.favIconUrl;

    // page title
    const displayName = !uiConfig?.displayName ? 'Simfuni' : uiConfig.displayName;
    document.title = `${displayName} | My Payments`;
};
