import { AccessTime, CalendarToday } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { InvoiceSummary, PaymentFrequency } from '../apis/invoices';
import { getPaymentAmount } from '../util/invoiceUtils';

export default ({ invoice }: { invoice: InvoiceSummary }) => {
    const nextPaymentDate = invoice.nextPayment?.dueDate;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 1, sm: 6 },
                justifyContent: 'flex-start',
                width: '100%',
            }}
        >
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <CalendarToday color='action' />
                <Box>
                    <Typography variant='caption'>Per {getFrequency(invoice.paymentFrequency)}</Typography>
                    <Typography variant='subtitle2' component='p'>
                        {currencyFormatter.format(getPaymentAmount(invoice))}
                    </Typography>
                </Box>
            </Box>
            {nextPaymentDate != null && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <AccessTime color='action' />
                    <Box>
                        <Typography variant='caption'>Next payment</Typography>
                        <Typography variant='subtitle2' component='p'>
                            {getDuration(nextPaymentDate)}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const getDuration = (dueDate: string): string => {
    const asMoment = moment(dueDate).startOf('day');
    const days = asMoment.diff(moment().startOf('day'), 'days');

    if (days < 0) {
        return 'Overdue';
    }

    if (days === 0) {
        return 'Today';
    }

    if (days === 1) {
        return 'Tomorrow';
    }

    if (days < 7) {
        return `${days} days`;
    }

    return asMoment.format('Do MMM YYYY');
};

const getFrequency = (frequency: PaymentFrequency | null): string => {
    if (frequency == null) {
        return '';
    }

    switch (frequency) {
        case PaymentFrequency.WEEKLY:
            return 'week';
        case PaymentFrequency.FORTNIGHTLY:
            return 'fortnight';
        case PaymentFrequency.MONTHLY:
            return 'month';
        case PaymentFrequency.IN_FULL:
            return '';
    }
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
