import { Alert, Box, Checkbox, FormControl, FormLabel, Link, TextField, Typography } from '@mui/material';
import {
    Control,
    Controller,
    ControllerRenderProps,
    FieldErrors,
    UseFormClearErrors,
    UseFormSetError,
} from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { PaymentFormFields } from './PaymentRadioGroup';

type Props = {
    directDebitAuthorityNumber?: string;
    instalmentAmount: number;
    control: Control<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
    setError: UseFormSetError<PaymentFormFields>;
    clearErrors: UseFormClearErrors<PaymentFormFields>;
};

export default ({ directDebitAuthorityNumber, instalmentAmount, control, setError, clearErrors, errors }: Props) => {
    const currencyFormatter = new Intl.NumberFormat('en-nz', {
        style: 'currency',
        currency: 'NZD',
    });

    const handleConfirmation = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: ControllerRenderProps<PaymentFormFields, 'confirmAuthority'>
    ) => {
        if (event.target.checked) {
            clearErrors('confirmAuthority');
        } else {
            setError('confirmAuthority', { message: 'Error' });
        }
        field.onChange(event);
    };

    return (
        <>
            <FormControl required fullWidth sx={{ mb: '20px' }}>
                <FormLabel>Account holder</FormLabel>
                <Controller
                    name='accountHolder'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size='small'
                            error={errors.accountHolder !== undefined}
                            helperText={errors.accountHolder?.message}
                            inputProps={{ 'data-testid': 'name' }}
                        />
                    )}
                />
            </FormControl>

            <FormControl required fullWidth sx={{ mb: '20px' }}>
                <FormLabel>Account number</FormLabel>
                <Controller
                    name='bankAccountNumber'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => {
                        return (
                            <ReactInputMask
                                mask='99-9999-9999999-99'
                                alwaysShowMask={true}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                            >
                                <TextField
                                    size='small'
                                    autoComplete='no'
                                    error={errors.bankAccountNumber !== undefined}
                                    helperText={errors.bankAccountNumber?.message}
                                    {...field}
                                    inputProps={{ 'data-testid': 'bankAccount' }}
                                />
                            </ReactInputMask>
                        );
                    }}
                />
            </FormControl>

            <Typography sx={{ mt: 1, mb: 2 }}>
                Your next instalment amount is{' '}
                <span style={{ display: 'inline', fontWeight: 600, fontSize: 18 }} data-testid='regularInstalment'>
                    {currencyFormatter.format(instalmentAmount)}
                </span>
                <span style={{ display: 'inline', fontSize: 11 }}> (includes processing fee).</span>
            </Typography>

            <Box sx={{ display: 'flex', ml: '-11px', alignItems: 'center' }}>
                <Box sx={{ position: 'relative' }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '20px',
                            height: '20px',
                            left: '14px',
                            top: '14px',
                            bgcolor: 'white',
                        }}
                    ></Box>
                    <Controller
                        name='confirmAuthority'
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                id='confirm-authority'
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                onChange={(e) => handleConfirmation(e, field)}
                            />
                        )}
                    />
                </Box>
                <FormLabel htmlFor='confirm-authority'>
                    I confirm that I have sole authority over this bank account.
                </FormLabel>
            </Box>

            {errors.confirmAuthority?.message && (
                <Alert severity='error'>
                    If you do not have authority over the bank account and still wish to pay using Direct Debit you can
                    download the{' '}
                    <Link href='https://djlju6s8ointy.cloudfront.net/docs/Simfuni_direct_debit_authority_v1.0.0.pdf'>
                        paper form
                    </Link>{' '}
                    and email a copy to{' '}
                    <Link href='mailto:directdebits@simfuni.com?subject=Direct Debit Authority Request'>
                        directdebits@simfuni.com
                    </Link>
                    .
                </Alert>
            )}

            <Typography variant='body1' sx={{ mt: 2 }}>
                Authority to accept direct debits. Authorisation code {directDebitAuthorityNumber}.
            </Typography>

            <Typography variant='body1' sx={{ mt: 2 }}>
                By completing the payment I/we authorise you to debit my/our account with the amounts of direct debits
                from Simfuni Ltd, with the authorisation code specified on this authority, in accordance with the{' '}
                <Link target='_blank' href='https://simfuni.com/direct-debit-terms-and-conditions'>
                    direct debit terms and conditions
                </Link>{' '}
                until further notice.
            </Typography>
        </>
    );
};
