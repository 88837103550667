import { Alert, AlertTitle } from '@mui/material';

export default function PendingCancellationAlert() {
    return (
        <Alert color='warning' variant='outlined' sx={{ py: 2 }}>
            <AlertTitle>Pending cancellation</AlertTitle>
            This insurance will be cancelled soon.
        </Alert>
    );
}
