import './index.css';
import App from './App';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import ReactDOM from 'react-dom/client';
import { Store } from './store/Store';
import { Provider } from 'react-redux';
import BreakpointProvider from './providers/BreakpointProvider';
import '@aws-amplify/ui-react/styles.css';
import SellerThemeProvider from './style/SellerThemeProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={Store}>
        <SellerThemeProvider>
            <BreakpointProvider>
                <App />
            </BreakpointProvider>
        </SellerThemeProvider>
    </Provider>
);
