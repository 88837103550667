import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { AnnotatedTermPaymentMethod, fetchLoan } from '../../../apis/invoices';
import { PaymentMethod } from '../../../apis/paymentMethodApi';
import { ExtendableFetchWrapper } from '../../../components/ExtendableFetchWrapper';
import StepsDrawer from '../../../components/StepsDrawer';
import { FetchStateType, isSuccess } from '../../../hooks/useFetch';
import { RootState } from '../../../store/Store';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import { setInvoice, setPaymentMethodState } from '../../../store/reducer/InvoiceReducer';
import { setInvoicesState } from '../../../store/reducer/InvoicesReducer';
import { setLoanState } from '../../../store/reducer/LoanReducer';
import SuccessStep from './SuccessStep';
import UpdateStep, { UpdateStepProps } from './UpdateStep';

type Props = {
    sessionId?: string | null;
    open: boolean;
    setClosed: () => void;
};

export default ({ open, setClosed, sessionId }: Props) => {
    const [step, setStep] = useState<1 | 2>(1);

    const invoicesState = useAppSelector((root: RootState) => root.InvoicesReducer).value;
    const { availablePaymentMethodsState, value: invoice } = useAppSelector((state) => state.persistedInvoiceReducer);
    const [newPaymentMethod, setNewPaymentMethod] = useState<PaymentMethod>();
    const [newCharges, setNewCharges] = useState<number>(0);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (newPaymentMethod != null) {
            setStep(2);
        }
    }, [newPaymentMethod]);

    const handleSetNewPaymentMethod = (paymentMethod: PaymentMethod, charges: number) => {
        setNewPaymentMethod(paymentMethod);
        setNewCharges(charges);
    };

    const handleClose = () => {
        if (newPaymentMethod != null) {
            dispatch(setPaymentMethodState({ value: newPaymentMethod, type: FetchStateType.SUCCESS }));
        }

        const updatedInvoicesState = cloneDeep(invoicesState);
        if (isSuccess(updatedInvoicesState)) {
            updatedInvoicesState.value.forEach((i) => {
                if (invoice.uuid === i.uuid) {
                    if (i.nextPayment) {
                        i.nextPayment.feeAmount = newCharges;
                        dispatch(setInvoice(i));
                    }
                }
            });
            dispatch(setInvoicesState(updatedInvoicesState));
        }

        fetchLoan(invoice.uuid).then((loan) => dispatch(setLoanState({ value: loan, type: FetchStateType.SUCCESS })));
        setStep(1);
        setClosed();
    };

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === 1 && (
                <ExtendableFetchWrapper<AnnotatedTermPaymentMethod[], UpdateStepProps>
                    state={availablePaymentMethodsState}
                    SuccessComponent={UpdateStep}
                    cancel={handleClose}
                    setNewPaymentMethod={handleSetNewPaymentMethod}
                    sessionId={sessionId}
                />
            )}
            {step === 2 && <SuccessStep handleClose={handleClose} />}
        </StepsDrawer>
    );
};

const stepNames = ['Change', 'Done'];
