import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import { InvoiceSummary } from '../apis/invoices';

import { OpenInNew } from '@mui/icons-material';
// @ts-ignore
import iQumulateLogo from '../images/iqumulate.svg';

export default ({ invoice, showRedirectUrl }: { invoice: InvoiceSummary; showRedirectUrl?: boolean }) => {
    const { customCheckoutDetail } = invoice;
    const { externalProviderReference, redirectUrl } = customCheckoutDetail!;

    return (
        <Paper variant='outlined' sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'row' }}>
                        <img src={iQumulateLogo} alt='IQumulate logo' />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='subtitle2'>Loan with IQumulate</Typography>
                            <Typography variant='subtitle1'>
                                {`This insurance loan ${externalProviderReference} is managed by the Premium Funder IQumulate.`}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                    {redirectUrl && showRedirectUrl && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'center', md: 'inherit' },
                                alignItems: { md: 'center' },
                                height: '100%',
                            }}
                        >
                            <Link href={redirectUrl} target='_blank' rel='noreferrer' underline='always'>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography variant='subtitle2' color='inherit'>
                                        Visit IQumulate
                                    </Typography>
                                    <OpenInNew sx={{ ml: 1 }} />
                                </Box>
                            </Link>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};
