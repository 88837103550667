import { createSlice } from '@reduxjs/toolkit';
import { InvoiceSummary } from '../../apis/invoices';
import { FetchState, FetchStateType } from '../../hooks/useFetch';

export type InvoicesAction = {
    type: string;
    payload: FetchState<InvoiceSummary[]>;
};

export const invoicesSlice = createSlice({
    name: 'invoice',
    initialState: {
        value: {
            type: FetchStateType.PENDING,
        } as FetchState<InvoiceSummary[]>,
    },
    reducers: {
        setInvoicesState: (state, action: InvoicesAction) => {
            state.value = action.payload;
        },
        clearInvoices: (state) => {
            state.value = {
                type: FetchStateType.PENDING,
            };
        },
    },
});

export const { setInvoicesState, clearInvoices } = invoicesSlice.actions;

export default invoicesSlice.reducer;
