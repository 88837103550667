import axios from 'axios';
import { PaymentMethodType } from './paymentMethodApi';

export type Loan = {
    uuid: string;
    status: LoanStatus;
    invoiceIdentifier: string;
    canUpdateSchedule: boolean;
    annualInterestRate: number;
    consolidatedPayments: ConsolidatedPayment[];
};

export type ConsolidatedPayment = {
    identifier: string;
    dueDate: string;
    amount: number;
    feeAmount?: number;
    paidAmount?: number;
};

export enum LoanStatus {
    APPLICATION = 'APPLICATION',
    OPEN = 'OPEN',
    APPROVAL_PENDING = 'APPROVAL_PENDING',
    CLOSED = 'CLOSED',
}

export const fetchLoan = async (invoiceUuid: string): Promise<Loan> => {
    return await axios
        .get(process.env.REACT_APP_CLIENT_PORTAL_HOST + '/invoices/' + invoiceUuid + '/loans')
        .then(({ data }) => data);
};

export type InvoiceSummary = {
    nextPayment: PaymentDto | null;
    number: string;
    portfolio: Portfolio;
    status: InvoiceStatus;
    cancellationDate?: string;
    uuid: string;
    paymentFrequency: PaymentFrequency | null;
    migrationStatus: MigrationStatus;
    customCheckoutDetail?: CustomCheckoutDetail;
    productLogoUrl?: string;
};

export enum CustomCheckoutType {
    IQUMULATE = 'IQUMULATE',
}

export type CustomCheckoutDetail = {
    customCheckoutType: CustomCheckoutType;
    externalProviderReference: string;
    redirectUrl?: string;
};

export enum InvoiceStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    PROCESSING = 'PROCESSING',
    PAID = 'PAID',
    OVERDUE = 'OVERDUE',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    DECLINED = 'DECLINED',
    ATTENTION = 'ATTENTION',
    CANCELLED = 'CANCELLED',
    PENDING_CANCELLATION = 'PENDING_CANCELLATION',
}

export enum MigrationStatus {
    NONE = 'NONE',
    PARTIAL = 'PARTIAL',
    FULL = 'FULL',
}

export enum PaymentFrequency {
    IN_FULL = 'IN_FULL',
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY',
}

export type PaymentDto = {
    instalmentAmount: number;
    feeAmount: number;
    dueDate: string | null;
};

export type TermPayment = {
    nextInstalmentAmount: number;
    nextInstalmentFeeAmount: number;
    regularInstalmentAmount: number;
    regularInstalmentFeeAmount: number;
};

export type Portfolio = {
    premiums: number;
    policies: Policy[];
};

export type Policy = {
    uuid: string;
    provider?: string;
    currentPolicyVersion: PolicyVersion;
    number: string;
    endDate: string;
    startDate: string;
    classCode?: string;
    classCodeDescription?: string | null;
};

type PolicyVersion = {
    premiums: number;
};

export const fetchSummary = async (): Promise<InvoiceSummary[]> => {
    return await axios.get(process.env.REACT_APP_CLIENT_PORTAL_HOST + '/summary').then(({ data }) => data);
};

export const fetchInvoice = async (uuid: string): Promise<InvoiceSummary> => {
    return await axios.get(process.env.REACT_APP_CLIENT_PORTAL_HOST + '/invoices/' + uuid).then(({ data }) => data);
};

export type AnnotatedTermPaymentMethod = {
    termPaymentMethod: TermPaymentMethod;
    paymentGatewayIdentifier: string;
    cardPaymentProcessor?: CardPaymentProcessor;
    directDebitAuthorityNumber?: string;
};

export enum CardPaymentProcessor {
    CYBERSOURCE = 'CYBERSOURCE',
    WINDCAVE = 'WINDCAVE',
}

export type TermPaymentMethod = {
    paymentMethodType: PaymentMethodType;
    paymentMethodCharges: Charge[];
};

export type Charge = {
    type: ChargeType;
    amount: number;
    initialAmount: number;
    calculatedOn: CalculatedOn;
    chargeValue: number;
};

export enum CalculatedOn {
    PERCENT = 'PERCENT',
    FIXED = 'FIXED',
}

export enum ChargeType {
    TRANSACTION_FEE = 'TRANSACTION_FEE',
    SETUP_FEE = 'SETUP_FEE',
}

export const fetchTermPaymentMethods = async (invoiceIdentifier: string): Promise<AnnotatedTermPaymentMethod[]> => {
    return await axios
        .get(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/term-payment-method`)
        .then(({ data }) => data);
};

export type AvailableTerm = {
    paymentFrequency: PaymentFrequency;
    earliestNextPaymentDate: string;
    latestNextPaymentDate: string;
};

export const fetchAvailableTerms = async (invoiceIdentifier: string): Promise<AvailableTerm[]> => {
    return await axios
        .get(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/terms`)
        .then(({ data }) => data);
};

export type AdjustmentOverview = {
    current: Adjustment;
    adjusted: Adjustment;
};

export type Adjustment = {
    nextPaymentDate: string;
    paymentFrequency: PaymentFrequency;
    termPayment: TermPayment;
};

export const queryAdjustment = async (
    invoiceIdentifier: string,
    paymentFrequency: PaymentFrequency,
    nextPaymentDate: string
): Promise<AdjustmentOverview> => {
    const url = new URL(
        `${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/_preview/adjustment`
    );
    url.searchParams.append('paymentFrequency', paymentFrequency);
    url.searchParams.append('nextPaymentDate', nextPaymentDate);

    return await axios.post(url.href).then(({ data }) => data);
};

export type AdjustmentResult = {
    invoice: InvoiceSummary;
    loan: Loan;
};

export const confirmAdjustment = async (
    invoiceIdentifier: string,
    paymentFrequency: PaymentFrequency,
    nextPaymentDate: string
): Promise<AdjustmentResult> => {
    const url = new URL(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/adjustment`);
    url.searchParams.append('paymentFrequency', paymentFrequency);
    url.searchParams.append('nextPaymentDate', nextPaymentDate);

    return await axios.patch(url.href).then(({ data }) => data);
};
