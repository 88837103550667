import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import StepsDrawer from '../../../components/StepsDrawer';
import { InvoiceSummary, Loan } from '../../../apis/invoices';
import { FetchStateType, isSuccess } from '../../../hooks/useFetch';
import { setInvoice } from '../../../store/reducer/InvoiceReducer';
import PaymentCompleteStep from './PaymentCompleteStep';
import PaymentStep from './PaymentStep';
import { RootState } from '../../../store/Store';
import { cloneDeep } from 'lodash';
import { setInvoicesState } from '../../../store/reducer/InvoicesReducer';
import { setLoanState } from '../../../store/reducer/LoanReducer';
import PageLoading from '../../../components/PageLoading';

type Props = {
    open: boolean;
    setClosed: () => void;
    title: string;
    description: string;
};

export default function MakePayment({ open, setClosed, title, description }: Readonly<Props>) {
    const [step, setStep] = useState<1 | 2>(1);
    const [updatedModels, setUpdatedModels] = useState<{ invoice: InvoiceSummary; loan: Loan }>();
    const invoicesState = useAppSelector((root: RootState) => root.InvoicesReducer).value;
    const { paymentMethodState } = useAppSelector((root: RootState) => root.persistedInvoiceReducer);
    const dispatch = useAppDispatch();

    const handleSetUpdatedModels = (invoice: InvoiceSummary, loan: Loan) => {
        setUpdatedModels({ invoice, loan });
        setStep(2);
    };

    const handleClose = () => {
        const updatedInvoice = updatedModels?.invoice;
        if (updatedInvoice != null) {
            dispatch(setInvoice(updatedInvoice));
            const updatedInvoicesState = cloneDeep(invoicesState);
            if (isSuccess(updatedInvoicesState)) {
                const index = updatedInvoicesState.value.findIndex((i) => updatedInvoice.uuid === i.uuid);
                updatedInvoicesState.value[index] = updatedInvoice;
                dispatch(setInvoicesState(updatedInvoicesState));
            }
        }
        const updatedLoan = updatedModels?.loan;
        if (updatedLoan != null) {
            dispatch(setLoanState({ type: FetchStateType.SUCCESS, value: updatedLoan }));
        }
        setStep(1);
        setClosed();
    };

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === 1 &&
                (isSuccess(paymentMethodState) ? (
                    <PaymentStep
                        cancel={handleClose}
                        setUpdatedModels={handleSetUpdatedModels}
                        paymentMethod={paymentMethodState.value}
                        title={title}
                        description={description}
                    />
                ) : (
                    <PageLoading />
                ))}
            {step === 2 && <PaymentCompleteStep handleClose={handleClose} />}
        </StepsDrawer>
    );
}

const stepNames = ['Pay', 'Done'];
