import axios, { AxiosResponseHeaders } from 'axios';

export type DownloadFileResult = {
    status: DownloadFileResultStatus;
    data?: Blob;
    filename?: string;
};

export enum DownloadFileResultStatus {
    INITIATED = 'INITIATED',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    NOT_GENERATED = 'NOT_GENERATED',
}

const fileNameFromHeaders = (headers: AxiosResponseHeaders, defaultName: string): string => {
    const matches = headers.get('Content-Disposition', /^attachment;filename="(.+)"$/);
    return matches?.[matches.length - 1] ?? defaultName;
};

export const downloadLoanContract = async (invoiceIdentifier: string): Promise<DownloadFileResult> => {
    const url = new URL(
        `${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/documents/loan-contract`
    );
    return await axios
        .get(url.href, { responseType: 'blob' })
        .then(({ data, headers }) => ({
            status: DownloadFileResultStatus.SUCCEEDED,
            data,
            filename: fileNameFromHeaders(headers as AxiosResponseHeaders, `${invoiceIdentifier}-loan-contract.pdf`),
        }))
        .catch((err) => {
            if (err.response?.status !== 425) {
                throw err;
            }

            return { status: DownloadFileResultStatus.NOT_GENERATED };
        });
};

export const downloadDirectDebitAuthority = async (invoiceIdentifier: string): Promise<DownloadFileResult> => {
    const url = new URL(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/documents/dda`);
    return await axios
        .get(url.href, { responseType: 'blob' })
        .then(({ data, headers }) => ({
            status: DownloadFileResultStatus.SUCCEEDED,
            data,
            filename: fileNameFromHeaders(headers as AxiosResponseHeaders, `${invoiceIdentifier}-dda.pdf`),
        }))
        .catch((err) => {
            if (err.response?.status !== 425) {
                throw err;
            }

            return { status: DownloadFileResultStatus.NOT_GENERATED };
        });
};
