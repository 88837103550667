import { Edit } from '@mui/icons-material';
import { Box, Divider, Link, Typography } from '@mui/material';
import { lowerCase, upperFirst } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { InvoiceSummary, LoanStatus, PaymentFrequency } from '../../apis/invoices';
import ResponsiveIconButton from '../../components/ResponsiveIconButton';
import { FetchStateType, isSuccess } from '../../hooks/useFetch';
import { RootState } from '../../store/Store';
import { useAppSelector } from '../../store/reducer/Hooks';
import { DATE_CARDINAL, DAY_OF_WEEK, isInFuture } from '../../util/dateUtils';
import ChangePaymentSchedule from './ChangePaymentSchedule';
import PaymentMethodRow from './PaymentMethodRow';
import PaymentsDialog from './PaymentsDialog';

export default () => {
    const [openPaymentSchedule, setOpenPaymentSchedule] = useState(false);
    const [openPaymentsDialog, setOpenPaymentsDialog] = useState(false);

    const { value: invoice } = useAppSelector((state: RootState) => state.persistedInvoiceReducer);
    const { value: loanState } = useAppSelector((state: RootState) => state.LoanReducer);
    const canEditPayments =
        loanState.type === FetchStateType.SUCCESS &&
        [LoanStatus.OPEN, LoanStatus.APPROVAL_PENDING].includes(loanState.value.status);
    const canEditPaymentSchedule = canEditPayments && loanState.value.canUpdateSchedule;

    const closePaymentSchedule = () => setOpenPaymentSchedule(false);
    const editPaymentSchedule = () => setOpenPaymentSchedule(true);

    const futurePayments = isSuccess(loanState)
        ? loanState.value.consolidatedPayments.filter(
              (ep) => (ep.paidAmount ?? 0) < ep.amount && isInFuture(ep.dueDate)
          )
        : [];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='subtitle2' component='p'>
                        Payment frequency
                    </Typography>
                    <Box>{buildFriendlyFrequency(invoice)}</Box>
                </Box>
                <Box>
                    <ResponsiveIconButton
                        data-testid='edit-payment-schedule'
                        icon={<Edit />}
                        onClick={editPaymentSchedule}
                        disabled={!canEditPaymentSchedule}
                        size='small'
                    >
                        Edit
                    </ResponsiveIconButton>
                    <ChangePaymentSchedule open={openPaymentSchedule} setClosed={closePaymentSchedule} />
                </Box>
            </Box>
            <Divider />
            <PaymentMethodRow canEditPayments={canEditPayments} />
            <Divider />
            {futurePayments.length > 0 && (
                <Box>
                    <Typography sx={{ display: 'inline-block', mr: '3px' }} variant='subtitle1' component='p'>
                        Payments are automatically deducted on the next payment day.
                    </Typography>
                    <Link
                        sx={{ display: 'inline-block', fontWeight: 500, textAlign: 'left' }}
                        onClick={() => setOpenPaymentsDialog(true)}
                        component='button'
                        underline='hover'
                        variant='subtitle1'
                    >
                        View all future billing payments
                    </Link>
                    <PaymentsDialog
                        open={openPaymentsDialog}
                        futurePayments={futurePayments}
                        handleClose={() => setOpenPaymentsDialog(false)}
                    />
                </Box>
            )}
        </Box>
    );
};

const buildFriendlyFrequency = (invoice: InvoiceSummary) => {
    const date = invoice.nextPayment?.dueDate;
    if (date == null) {
        return upperFirst(lowerCase(invoice.paymentFrequency ?? ''));
    }

    const nextPaymentDate = moment(date);

    if (invoice.paymentFrequency === PaymentFrequency.WEEKLY) {
        return (
            <Typography variant='body1' fontWeight={300}>
                Weekly on {nextPaymentDate.format(DAY_OF_WEEK)}s
            </Typography>
        );
    }

    if (invoice.paymentFrequency === PaymentFrequency.FORTNIGHTLY) {
        return (
            <Typography variant='body1' fontWeight={300}>
                Fortnightly on {nextPaymentDate.format(DAY_OF_WEEK)}s
            </Typography>
        );
    }

    if (invoice.paymentFrequency === PaymentFrequency.MONTHLY) {
        const dayOfMonth = Number(nextPaymentDate.date());
        const standardMessage = (
            <Typography variant='body1' fontWeight={300} component='span'>
                Monthly on the {nextPaymentDate.format(DATE_CARDINAL)}
            </Typography>
        );

        if (dayOfMonth > 28) {
            return (
                <span>
                    {standardMessage}
                    <Typography fontSize='12px' component='span'>
                        * Or the last day of the month for months with less than {dayOfMonth} days
                    </Typography>
                </span>
            );
        }

        return standardMessage;
    }
};
