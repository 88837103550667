import { Box, Divider, Typography } from '@mui/material';
import { useAppSelector } from '../../store/reducer/Hooks';
import { RootState } from '../../store/Store';
import NextPaymentDetails from '../../components/NextPaymentDetails';
import { InvoiceStatus, MigrationStatus, PaymentFrequency } from '../../apis/invoices';
import ActionAreas from './ActionAreas';
import PaidUntil from '../../components/PaidUntil';
import PaymentMethodRow from './PaymentMethodRow';

export default function PaymentDetails() {
    const { value: invoice } = useAppSelector((state: RootState) => state.persistedInvoiceReducer);
    const hasNextRecurringPayment =
        invoice.nextPayment != null && invoice.paymentFrequency !== PaymentFrequency.IN_FULL;
    const paymentRequired = invoice.status === InvoiceStatus.PARTIALLY_PAID;
    const partiallyMigrated = invoice.migrationStatus === MigrationStatus.PARTIAL;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant='h5' component='h2'>
                Payment
            </Typography>
            {hasNextRecurringPayment && (
                <>
                    <NextPaymentDetails invoice={invoice} />
                    <ActionAreas />
                </>
            )}
            {(paymentRequired || partiallyMigrated) && (
                <>
                    <PaymentMethodRow canEditPayments /> <Divider />
                </>
            )}
            {!hasNextRecurringPayment && !paymentRequired && !partiallyMigrated && <PaidUntil invoice={invoice} />}
        </Box>
    );
}
