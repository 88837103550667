import { createSlice } from '@reduxjs/toolkit';
import { UIConfig } from '../../apis/seller';

type SellerUIConfigAction = {
    type: string;
    payload: UIConfig;
};

const initialState: { uiConfig?: UIConfig } = {
    uiConfig: undefined,
};

export const sellerSlice = createSlice({
    name: 'seller',
    initialState,
    reducers: {
        setSellerUIConfig: (state, action: SellerUIConfigAction) => {
            state.uiConfig = action.payload;
        },
    },
});

export const { setSellerUIConfig } = sellerSlice.actions;

export default sellerSlice.reducer;
