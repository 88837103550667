import { Done } from '@mui/icons-material';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { common, grey } from '@mui/material/colors';
import { Fragment } from 'react';

type Props<T extends number> = {
    selectedStep: T;
    stepNames: string[];
};

export default <T extends number>({ selectedStep, stepNames }: Props<T>) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {stepNames.map<React.ReactNode>((name, index) => {
                const stepNumber = index + 1;
                return (
                    <Fragment key={name}>
                        <Step
                            step={stepNumber}
                            stepName={name}
                            selected={selectedStep === stepNumber}
                            completed={selectedStep > stepNumber}
                        />
                        {stepNumber !== stepNames.length && <Divider sx={{ flexGrow: 1 }} />}
                    </Fragment>
                );
            })}
        </Box>
    );
};

const Step = ({
    step,
    stepName,
    ...rest
}: {
    step: number;
    stepName: string;
    selected: boolean;
    completed?: boolean;
}) => {
    return (
        <>
            <StepNumber step={step} {...rest} />
            <StepDescription stepName={stepName} {...rest} />
        </>
    );
};

const StepDescription = ({
    stepName,
    selected,
    completed,
}: {
    stepName: string;
    selected: boolean;
    completed?: boolean;
}) => {
    return (
        <Box>
            <Typography variant={selected || completed ? 'subtitle2' : 'body2'}>{stepName}</Typography>
        </Box>
    );
};

const StepNumber = ({ step, selected, completed }: { selected?: boolean; step: number; completed?: boolean }) => {
    const theme = useTheme();

    const getBackgroundColor = () => {
        if (completed || selected) {
            return theme.palette.primary.main;
        }

        return grey['500'];
    };

    return (
        <Box
            sx={{
                backgroundColor: getBackgroundColor(),
                borderRadius: 12,
                width: '24px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: common.white,
            }}
        >
            {completed ? (
                <Done fontSize='small' />
            ) : (
                <Typography variant='caption' fontSize={12} color={common.white}>
                    {step}
                </Typography>
            )}
        </Box>
    );
};
