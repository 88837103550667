import { AuthorizedIndividual } from '../apis/myProfile';

type Props = {
    authorizedIndividual: AuthorizedIndividual;
};

export default ({ authorizedIndividual }: Props) => {
    return (
        <>
            {authorizedIndividual.personalDetails.givenName} {authorizedIndividual.personalDetails.surname}
            <br />
            {authorizedIndividual.contactDetails.email.address}
            <br />
            {authorizedIndividual.contactDetails.preferredPhoneNumber.number}
        </>
    );
};
