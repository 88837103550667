import { InvoiceSummary, Policy } from '../apis/invoices';

export const getPaymentAmount = (invoice: InvoiceSummary) => {
    return (invoice.nextPayment?.instalmentAmount ?? 0) + (invoice.nextPayment?.feeAmount ?? 0);
};

const NOT_SET = 'NOT_SET';

export const getClassCodeDescription = (policy: Policy): string | undefined => {
    if (policy.classCode === NOT_SET) {
        return;
    }

    if (policy.classCodeDescription) {
        return policy.classCodeDescription;
    }

    return policy.classCode;
};
