import { Alert, AlertTitle } from '@mui/material';

export default () => {
    return (
        <Alert variant='outlined' severity='info' sx={{ py: 2 }}>
            <AlertTitle>Improved experience coming soon</AlertTitle>
            If this insurance renews, the next time you visit you'll find an improved payment experience here, with more
            information and easy to use self service tools.
        </Alert>
    );
};
