import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { InvoiceSummary } from '../../../apis/invoices';
import { getPaymentAmount } from '../../../util/invoiceUtils';
import moment from 'moment';
import { DATE_FRIENDLY } from '../../../util/dateUtils';
import { useMemo, useState } from 'react';
import MakePayment from './MakePayment';

type Props = {
    invoice: InvoiceSummary;
};

export default function OverdueAlert({ invoice }: Readonly<Props>) {
    const [openMakePayment, setOpenMakePayment] = useState(false);
    const nextPaymentDate = invoice.nextPayment?.dueDate;
    const payingToday = nextPaymentDate != null && moment(nextPaymentDate).isSameOrBefore(moment().endOf('day'));

    const message = useMemo(() => {
        if (payingToday) {
            return "You don't need to do anything as your payment will be reattempted within 24 hours.";
        }

        if (nextPaymentDate != null) {
            return `Your payment will be reattempted on ${moment(nextPaymentDate).format(DATE_FRIENDLY)} unless paid prior.`;
        }

        return null;
    }, [payingToday, nextPaymentDate]);

    return (
        <Alert severity={payingToday ? 'warning' : 'error'} variant='outlined' sx={{ py: 2 }}>
            <AlertTitle>{currencyFormatter.format(getPaymentAmount(invoice))} Overdue</AlertTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <span>
                    Your recent automatic payment has failed.
                    {message != null && ` ${message}`}
                </span>
                {!payingToday && (
                    <>
                        <Button
                            sx={{ width: '228px' }}
                            color='error'
                            variant='contained'
                            onClick={() => setOpenMakePayment(true)}
                        >
                            Make a payment
                        </Button>
                        <MakePayment
                            open={openMakePayment}
                            setClosed={() => setOpenMakePayment(false)}
                            title='Pay overdue balance'
                            description='Overdue'
                        />
                    </>
                )}
            </Box>
        </Alert>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
