import axios from 'axios';
import { CardPaymentProcessor } from './invoices';

export enum PaymentMethodType {
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    CREDIT_CARD = 'CREDIT_CARD',
}

export enum PaymentMethodStatus {
    CREATED = 'CREATED',
    VOID = 'VOID',
    VERIFIED = 'VERIFIED',
}

type BasePaymentMethod = {
    uuid: string;
    type: PaymentMethodType;
    accountIdentifier: string;
    isActive: boolean;
    status: PaymentMethodStatus;
    invoiceIdentifier: string;
    paymentGatewayIdentifier: string;
};

export type CardPaymentMethod = BasePaymentMethod & {
    cardType: number;
    maskedCardNumber: string;
    dishonourCode?: string;
    dishonourMessage?: string;
};

export type BankAccountPaymentMethod = BasePaymentMethod & {
    accountHolder: number;
    bankAccountNumber: string;
    bankName: string;
};

export type PaymentMethod = CardPaymentMethod | BankAccountPaymentMethod;

export type BankAccount = {
    accountHolder: string;
    bankAccountNumber: string;
    confirmAuthority: boolean;
};

export type PaymentTokenRequest = {
    token: string;
};

export const fetchCurrentPaymentMethod = async (invoiceUuid: string): Promise<PaymentMethod> => {
    const url = `${process.env.REACT_APP_CLIENT_PORTAL_HOST}/payment-method?invoice-identifier=${invoiceUuid}`;

    return await axios.get(url).then(({ data }) => ({ ...data, invoiceIdentifier: invoiceUuid }));
};

export const authoriseNewDirectDebit = async (
    invoiceUuid: string,
    bankAccount: BankAccount
): Promise<BankAccountPaymentMethod> => {
    const url = `${process.env.REACT_APP_CLIENT_PORTAL_HOST}/payment-method/direct-debit?invoice-identifier=${invoiceUuid}`;

    return await axios.post(url, bankAccount).then(({ data }) => data);
};

export const authoriseNewCardToken = async (
    invoiceUuid: string,
    paymentGatewayIdentifier: string,
    cardPaymentProcessor: CardPaymentProcessor,
    authToken: PaymentTokenRequest
): Promise<PaymentMethod> => {
    const url = `${process.env.REACT_APP_CLIENT_PORTAL_HOST}/payment-method/card`;

    return await axios
        .post(url, authToken, {
            params: {
                'invoice-identifier': invoiceUuid,
                paymentGatewayIdentifier,
                cardPaymentProcessor,
            },
        })
        .then(({ data }) => data);
};

export const fetchPaymentSession = async (
    invoiceIdentifier: string,
    paymentGatewayIdentifier: string,
    cardPaymentProcessor: CardPaymentProcessor
): Promise<string> => {
    return await axios
        .post(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/payment-method/session`, null, {
            params: { paymentGatewayIdentifier, cardPaymentProcessor, invoiceIdentifier },
        })
        .then(({ data }) => data);
};

type WindcaveTokenCompleteResponse = {
    paymentMethodIdentifier: string;
};

export const completeTokenSession = async (sessionId: string): Promise<WindcaveTokenCompleteResponse> => {
    return await axios
        .post(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/payment-method/token/session/complete`, null, {
            params: { sessionId },
        })
        .then(({ data }) => data);
};

export const fetchPaymentMethodStatus = async (paymentMethodIdentifier: string): Promise<PaymentMethod> => {
    return await axios
        .get(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/payment-method/${paymentMethodIdentifier}/status`)
        .then(({ data }) => data);
};

// type guards

export const isBankAccount = (toBeDetermined?: PaymentMethod): toBeDetermined is BankAccountPaymentMethod => {
    return toBeDetermined?.type === PaymentMethodType.DIRECT_DEBIT;
};

export const isCard = (toBeDetermined?: PaymentMethod): toBeDetermined is CardPaymentMethod => {
    return toBeDetermined?.type === PaymentMethodType.CREDIT_CARD;
};
