export default (setLoading: () => void, setLoaded: () => void) => {
    setLoading();

    const s = document.createElement('script');
    s.src = 'https://flex.cybersource.com/microform/bundle/v1/flex-microform.min.js';
    s.type = 'text/javascript';
    s.async = false;
    s.onload = setLoaded;
    document.body.appendChild(s);
};
