import { Box, InputLabel } from '@mui/material';
import { AvailableTerm } from '../../../../apis/invoices';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DATE_COMPACT, DATE_SERVER_FORMAT } from '../../../../util/dateUtils';

type Props = {
    nextPaymentDate: string;
    setNextPaymentDate: (date: string) => void;
    selectedTerm: AvailableTerm;
    errorMessage?: string;
};

export default ({ nextPaymentDate, setNextPaymentDate, selectedTerm, errorMessage }: Props) => {
    const minDate = moment(selectedTerm.earliestNextPaymentDate, DATE_SERVER_FORMAT);
    const maxDate = moment(selectedTerm.latestNextPaymentDate, DATE_SERVER_FORMAT);

    const handleSetDate = (newValue: Moment | null) => {
        setNextPaymentDate(newValue == null ? '' : newValue.format(DATE_SERVER_FORMAT));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <InputLabel htmlFor='next-payment-date' error={!!errorMessage}>
                    Next payment date
                </InputLabel>
                <DatePicker
                    sx={{ width: '100%' }}
                    onChange={handleSetDate}
                    slotProps={{
                        textField: {
                            helperText: errorMessage,
                        },
                    }}
                    format={DATE_COMPACT}
                    value={moment(nextPaymentDate, DATE_SERVER_FORMAT)}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </Box>
        </LocalizationProvider>
    );
};
