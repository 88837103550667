import { isEmpty } from 'lodash';
import { useAppSelector } from '../store/reducer/Hooks';

// @ts-ignore
import simfuniLogo from '../images/simfuni_logo.svg';

export default function Logo({ width }: { width: string }) {
    const uiConfig = useAppSelector((root) => root.persistedSellerReducer).uiConfig;

    return uiConfig != null && !isEmpty(uiConfig?.logoUrl) ? (
        <img alt='Brand Logo' src={uiConfig?.logoUrl} width={width} />
    ) : (
        <img alt='Simfuni Logo' src={simfuniLogo} width={width} />
    );
}
