import { Card } from '@aws-amplify/ui-react';
import { Theme, useTheme } from '@mui/material';

export type Props = {
    children: JSX.Element;
};

export const getAmplifyCardStyles = (theme: Theme): React.CSSProperties => {
    return {
        border: `1px solid ${theme.palette.secondary.light}`,
        margin: 'auto',
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    };
};

export default (props: Props) => {
    const theme = useTheme();

    return (
        <Card variation='elevated' style={getAmplifyCardStyles(theme)}>
            {props.children}
        </Card>
    );
};
