import { Box, Typography } from '@mui/material';
import { fetchMyProfile } from '../../apis/myProfile';
import { FetchWrapper } from '../../components/FetchWrapper';
import { useFetch } from '../../hooks/useFetch';
import Details from './Details';

export default () => {
    const profileState = useFetch(fetchMyProfile);

    return (
        <Box>
            <Typography variant='h3' component='h1' mb={4}>
                Personal info
            </Typography>
            <FetchWrapper state={profileState} SuccessComponent={Details} />
        </Box>
    );
};
