import { Box } from '@mui/material';
import { InvoiceSummary } from '../../apis/invoices';
import InvoiceCard from './InvoiceCard';

type Props = {
    invoices: InvoiceSummary[];
};

export default ({ invoices }: Props) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            {invoices.map((invoice) => (
                <InvoiceCard key={invoice.uuid} invoice={invoice} />
            ))}
        </Box>
    );
};
