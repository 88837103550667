import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import EditStep from './EditStep';
import ReviewStep from './ReviewStep';
import DoneStep from './DoneStep';
import StepsDrawer from '../../../components/StepsDrawer';
import { AdjustmentOverview, AdjustmentResult } from '../../../apis/invoices';
import { setLoanState } from '../../../store/reducer/LoanReducer';
import { FetchStateType, isSuccess } from '../../../hooks/useFetch';
import { setInvoice } from '../../../store/reducer/InvoiceReducer';
import { RootState } from '../../../store/Store';
import { cloneDeep } from 'lodash';
import { setInvoicesState } from '../../../store/reducer/InvoicesReducer';

type Props = {
    open: boolean;
    setClosed: () => void;
};

export default ({ open, setClosed }: Props) => {
    const [step, setStep] = useState<1 | 2 | 3>(1);
    const [adjustmentOverview, setAdjustmentOverview] = useState<AdjustmentOverview>();
    const [adjustmentResult, setAdjustmentResult] = useState<AdjustmentResult>();
    const invoicesState = useAppSelector((root: RootState) => root.InvoicesReducer).value;
    const dispatch = useAppDispatch();

    const handleSetAdjustmentOverview = (overview: AdjustmentOverview) => {
        setAdjustmentOverview(overview);
        setStep(2);
    };

    const handleAdjustmentResult = (res: AdjustmentResult) => {
        setAdjustmentResult(res);
        setStep(3);
    };

    const handleClose = () => {
        if (adjustmentResult != null) {
            dispatch(setLoanState({ value: adjustmentResult.loan, type: FetchStateType.SUCCESS }));
            dispatch(setInvoice(adjustmentResult.invoice));
            const updatedInvoicesState = cloneDeep(invoicesState);
            if (isSuccess(updatedInvoicesState)) {
                const index = updatedInvoicesState.value.findIndex((i) => adjustmentResult.invoice.uuid === i.uuid);
                updatedInvoicesState.value[index] = adjustmentResult.invoice;
                dispatch(setInvoicesState(updatedInvoicesState));
            }
        }
        setStep(1);
        setClosed();
    };

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === 1 && <EditStep cancel={handleClose} setAdjustmentOverview={handleSetAdjustmentOverview} />}
            {step === 2 && (
                <ReviewStep
                    cancel={handleClose}
                    handleAdjustmentResult={handleAdjustmentResult}
                    adjustmentOverview={adjustmentOverview!}
                />
            )}
            {step === 3 && <DoneStep handleClose={handleClose} />}
        </StepsDrawer>
    );
};

const stepNames = ['Edit', 'Review', 'Done'];
