import { Lock } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export default function WindcavePayment() {
    return (
        <Box sx={{ display: 'flex', gap: 2, my: 2, alignItems: 'center', maxWidth: 500 }}>
            <Lock color='action' />
            <Typography>You will be redirected to a secure page to input your payment details.</Typography>
        </Box>
    );
}
