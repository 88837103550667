import { Box, Divider, Typography } from '@mui/material';

export default ({ title, description }: { title: string; description: string | React.ReactNode }) => {
    return (
        <Box>
            <Typography variant='subtitle2' component='h2'>
                {title}
            </Typography>
            <Typography>{description}</Typography>
            <Divider sx={{ mt: 2 }} />
        </Box>
    );
};
