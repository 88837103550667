import { Alert, AlertTitle } from '@mui/material';

export default function PaymentRequiredTile() {
    return (
        <Alert variant='outlined' color='warning' sx={{ width: '100%' }}>
            <AlertTitle>Payment required</AlertTitle>
            Your application to pay over time was declined. The full remaining balance is due now.
        </Alert>
    );
}
