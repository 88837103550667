import { createSlice } from '@reduxjs/toolkit';
import { Loan } from '../../apis/invoices';
import { FetchState, FetchStateType } from '../../hooks/useFetch';

export type LoanAction<T> = {
    type: string;
    payload: T;
};

const initialLoanState = {
    type: FetchStateType.PENDING,
} as FetchState<Loan>;

export const loanSlice = createSlice({
    name: 'invoice',
    initialState: {
        value: { ...initialLoanState },
    },
    reducers: {
        setLoanState: (state, action: LoanAction<FetchState<Loan>>) => {
            state.value = action.payload;
        },
        clearLoan: (state) => {
            state.value = { ...initialLoanState };
        },
    },
});

export const { setLoanState, clearLoan } = loanSlice.actions;

export default loanSlice.reducer;
