import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { Policy } from '../../apis/invoices';
import PolicyDescription from '../../components/PolicyDescription';
import { useAppSelector } from '../../store/reducer/Hooks';
import { RootState } from '../../store/Store';
import { DATE_COMPACT } from '../../util/dateUtils';

export default () => {
    const invoice = useAppSelector((state: RootState) => state.persistedInvoiceReducer).value;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant='h5' component='h2'>
                Policies
            </Typography>
            {invoice.portfolio.policies.map((policy) => (
                <PolicyDetail key={policy.number} policy={policy} productLogoUrl={invoice.productLogoUrl} />
            ))}
        </Box>
    );
};

const PolicyDetail = ({ policy, productLogoUrl }: { policy: Policy; productLogoUrl?: string }) => {
    return (
        <Box
            sx={{
                p: { xs: 2, sm: 4 },
                borderColor: grey['300'],
                borderRadius: 2,
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <PolicyDescription policy={policy} productLogoUrl={productLogoUrl} />
            <Box>
                <Box>
                    <Typography variant='subtitle2' component='p'>
                        Policy started
                    </Typography>
                    <Typography variant='caption'>{moment(policy.startDate).format(DATE_COMPACT)}</Typography>
                </Box>
            </Box>
            {policy.provider && policy.provider !== 'NOT_SET' && (
                <Box>
                    <Typography variant='caption' fontSize={12}>
                        Insured by {policy.provider}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
