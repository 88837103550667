import { Box } from '@mui/system';
import StepCard from '../../../components/StepCard';
import { CheckCircle } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

type Props = {
    handleClose: () => void;
};

export default function PaymentCompleteStep({ handleClose }: Readonly<Props>) {
    return (
        <StepCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CheckCircle color='success' fontSize='large' />
                    <Typography variant='h5' component='h2'>
                        Payment complete
                    </Typography>
                </Box>
                <Typography textAlign='center'>Your payment has been made successfully.</Typography>
                <Button onClick={handleClose} variant='contained'>
                    Close
                </Button>
            </Box>
        </StepCard>
    );
}
