import { Download, ErrorOutline, HelpOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { DownloadFileResult, DownloadFileResultStatus } from '../../apis/document';
import ResponsiveIconLink from '../../components/ResponsiveIconLink';
import { InvoiceSummary } from '../../apis/invoices';
import { useEffect, useState } from 'react';
import { toLower } from 'lodash';

// @ts-ignore
import pdfLogo from '../../images/pdf.svg';

type DocumentItemProps = {
    category: string;
    invoice: InvoiceSummary;
    fileDownloader: (invoiceIdentifier: string) => Promise<DownloadFileResult>;
};

export default function DocumentItem({ category, invoice, fileDownloader }: Readonly<DocumentItemProps>) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState(DownloadFileResultStatus.INITIATED);
    const [fileName, setFileName] = useState(`${invoice.uuid}-${toLower(category).replace(' ', '-')}.pdf`);
    const [processingTooltipOpen, setProcessingTooltipOpen] = useState(false);
    const [errorTooltipOpen, setErrorTooltipOpen] = useState(false);

    const [url, setUrl] = useState('#');
    const [size, setSize] = useState(0);

    const loadDocument = () => {
        setLoading(true);
        setError(undefined);

        fileDownloader(invoice.uuid)
            .then(({ status, data, filename }) => {
                setStatus(status);
                if (data) {
                    setUrl(URL.createObjectURL(data));
                    setSize(data.size);
                }
                if (filename) {
                    setFileName(filename);
                }
            })
            .catch(() => {
                setError(`Failed to load ${category}`);
                setStatus(DownloadFileResultStatus.FAILED);
            })
            .finally(() => setLoading(false));
    };

    useEffect(loadDocument, [category, fileDownloader, invoice.uuid]);

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <img src={pdfLogo} alt='pdf logo' width='24px' />
                            <Typography variant='body1'>{category}</Typography>
                        </Box>
                        {size > 0 && (
                            <Typography variant='body2' sx={{ ml: 0.5 }}>
                                ({formatSize(size)})
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', '& hr': { mx: 1 } }}>
                        {loading && <CircularProgress size={16} />}
                        {status === DownloadFileResultStatus.SUCCEEDED && (
                            <>
                                <ResponsiveIconLink
                                    data-testid='view-link'
                                    href={url}
                                    icon={<VisibilityOutlined sx={{ mb: -1 }} />}
                                >
                                    View
                                </ResponsiveIconLink>
                                <Divider orientation='vertical' flexItem />
                                <ResponsiveIconLink
                                    data-testid='download-link'
                                    href={url}
                                    download={fileName}
                                    icon={<Download sx={{ mb: -1 }} />}
                                >
                                    Download
                                </ResponsiveIconLink>
                            </>
                        )}
                        {status === DownloadFileResultStatus.NOT_GENERATED && (
                            <>
                                <Typography variant='body2'>Processing</Typography>
                                <Tooltip
                                    title='Your file is being generated. Please try again in a few minutes.'
                                    sx={{ mt: -1 }}
                                    open={processingTooltipOpen}
                                    onClose={() => setProcessingTooltipOpen(false)}
                                >
                                    <IconButton onClick={() => setProcessingTooltipOpen(true)}>
                                        <HelpOutlined fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {error != null && (
                            <>
                                <Typography variant='body2'>Failed to load</Typography>
                                <Tooltip
                                    title='Something went wrong, please try again later.'
                                    sx={{ mt: -1 }}
                                    open={errorTooltipOpen}
                                    onClose={() => setErrorTooltipOpen(false)}
                                >
                                    <IconButton onClick={() => setErrorTooltipOpen(true)}>
                                        <ErrorOutline fontSize='small' color='error' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Divider />
        </>
    );
}

const oneKB = 1024;
const oneMB = 1024 * 1024;
const formatSize = (size: number): string => {
    if (size >= oneMB) {
        return `${(size / oneMB).toFixed(2)}MB`;
    } else if (size >= oneKB) {
        return `${(size / oneKB).toFixed(2)}KB`;
    } else {
        return `${size}B`;
    }
};
