import { Link, LinkProps, useTheme } from '@mui/material';
import { useAppSelector } from '../store/reducer/Hooks';
import { RootState } from '../store/Store';
import { BREAKPOINT } from '../store/reducer/BreakpointReducer';

type Props = LinkProps & {
    icon: React.ReactNode;
};

export default (props: Props) => {
    const theme = useTheme();
    const { up } = useAppSelector((state: RootState) => state.BreakpointReducer);

    return (
        <Link target='_blank' underline='none' variant='body2' {...props}>
            {up(BREAKPOINT.sm) && <span style={{ marginRight: theme.spacing(1) }}>{props.children}</span>}
            {props.icon}
        </Link>
    );
};
