import { CardPaymentProcessor } from '../../../apis/invoices';
import {
    PaymentMethod,
    PaymentMethodStatus,
    authoriseNewCardToken,
    fetchPaymentMethodStatus,
    isCard,
} from '../../../apis/paymentMethodApi';

const MAX_POLLS = 30;

export default async ({
    setLoadingPreSubmit,
    setLoadingRes,
    preSubmitHelper,
    invoiceUuid,
    newCardPaymentMethod,
    pollingCount,
    setFormError,
    setNewPaymentMethod,
    paymentGatewayIdentifier,
    cardPaymentProcessor,
}: {
    setLoadingPreSubmit: (loading: boolean) => void;
    setLoadingRes: (loading: boolean) => void;
    preSubmitHelper: () => Promise<string>;
    invoiceUuid: string;
    newCardPaymentMethod: React.MutableRefObject<PaymentMethod | null>;
    pollingCount: React.MutableRefObject<number>;
    setFormError: (error: string | null) => void;
    setNewPaymentMethod: (paymentMethod: PaymentMethod) => void;
    paymentGatewayIdentifier: string;
    cardPaymentProcessor: CardPaymentProcessor;
}) => {
    setFormError(null);
    setLoadingPreSubmit(true);
    await preSubmitHelper()
        .then((token) => {
            setLoadingPreSubmit(false);
            setLoadingRes(true);
            return authoriseNewCardToken(invoiceUuid, paymentGatewayIdentifier, cardPaymentProcessor, { token });
        })
        .then((paymentMethodResp) => {
            newCardPaymentMethod.current = paymentMethodResp;
            setTimeout(() => checkPaymentMethodStatus(), 500);
        })
        .catch(() => {
            setLoadingPreSubmit(false);
            setLoadingRes(false);
            setFormError('Something went wrong, please try again.');
        });

    const checkPaymentMethodStatus = () => {
        const failed = (errorMsg?: string) => {
            if (errorMsg) {
                setFormError(`Authorization failed: ${errorMsg}. Please try again.`);
            } else {
                setFormError('Authorization failed, please try again.');
            }
            setLoadingRes(false);
            pollingCount.current = 0;
        };

        ++pollingCount.current;

        if (!newCardPaymentMethod.current) {
            return failed();
        }

        if (pollingCount.current === MAX_POLLS) {
            return failed();
        }

        fetchPaymentMethodStatus(newCardPaymentMethod.current?.uuid)
            .then((paymentMethodResp) => {
                switch (paymentMethodResp.status) {
                    case PaymentMethodStatus.CREATED:
                        setTimeout(() => checkPaymentMethodStatus(), 500);
                        break;

                    case PaymentMethodStatus.VOID:
                        newCardPaymentMethod.current = null;
                        return failed(isCard(paymentMethodResp) ? paymentMethodResp.dishonourMessage : undefined);

                    case PaymentMethodStatus.VERIFIED:
                        setNewPaymentMethod(newCardPaymentMethod.current as PaymentMethod);
                        setLoadingRes(false);
                }
            })
            .catch(console.log);
    };
};
