import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { FetchWrapper } from '../../components/FetchWrapper';
import { InvoiceStatus, InvoiceSummary, fetchSummary } from '../../apis/invoices';
import { CheckCircle } from '@mui/icons-material';
import InvoiceList from './InvoiceList';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setInvoicesState } from '../../store/reducer/InvoicesReducer';
import { RootState } from '../../store/Store';
import { FetchStateType, simpleStoreFetch } from '../../hooks/useFetch';
import { getPaymentAmount } from '../../util/invoiceUtils';

export default () => {
    const storeInvoicesState = useAppSelector((state: RootState) => state.InvoicesReducer).value;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (![FetchStateType.SUCCESS, FetchStateType.LOADING].includes(storeInvoicesState.type)) {
            simpleStoreFetch({ dispatch, setState: setInvoicesState, fetch: () => fetchSummary() });
        }
    }, []);

    const Invoices = useCallback(({ data }: { data: InvoiceSummary[] }) => {
        const overdueAmount = data
            .filter((invoice) => invoice.status === InvoiceStatus.OVERDUE)
            .reduce((a, invoice) => a + getPaymentAmount(invoice), 0);

        return (
            <Box>
                {overdueAmount > 0 ? (
                    <Alert severity='error' variant='standard' sx={{ my: 4 }}>
                        <AlertTitle>Payments overdue</AlertTitle>
                        You have {currencyFormatter.format(overdueAmount)} overdue.
                    </Alert>
                ) : (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', my: 4 }}>
                        <CheckCircle color='success' fontSize='large' />
                        <Typography component='h2'>Payments up to date</Typography>
                    </Box>
                )}
                <InvoiceList invoices={data} />
            </Box>
        );
    }, []);

    return (
        <Box>
            <Typography variant='h3' component='h1'>
                My insurance
            </Typography>
            <FetchWrapper state={storeInvoicesState} SuccessComponent={Invoices} />
        </Box>
    );
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
