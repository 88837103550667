import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Policy } from '../apis/invoices';
import Car from '../images/classCodeIcons/Car';
import Caravan from '../images/classCodeIcons/Caravan';
import Fire from '../images/classCodeIcons/Fire';
import Generic from '../images/classCodeIcons/Generic';
import Glass from '../images/classCodeIcons/Glass';
import Handcuffs from '../images/classCodeIcons/Handcuffs';
import Horse from '../images/classCodeIcons/Horse';
import House from '../images/classCodeIcons/House';
import Laptop from '../images/classCodeIcons/Laptop';
import Money from '../images/classCodeIcons/Money';
import Person from '../images/classCodeIcons/Person';
import Plane from '../images/classCodeIcons/Plane';
import Tractor from '../images/classCodeIcons/Tractor';
import { getClassCodeDescription } from '../util/invoiceUtils';

export default ({ policy, productLogoUrl }: { policy: Policy; productLogoUrl?: string }) => {
    const classCode = getClassCodeDescription(policy);

    return (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {productLogoUrl ? (
                <img src={productLogoUrl} style={{ width: '48px', height: '48px' }} alt='Seller Product logo' />
            ) : (
                <Box
                    sx={{
                        background: grey['200'],
                        width: '48px',
                        height: '48px',
                        borderRadius: '48px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {getPolicyImage(policy)}
                </Box>
            )}
            {classCode ? (
                <Box>
                    <Typography variant='h6' component='h3'>
                        {classCode}
                    </Typography>
                    <Typography variant='body2'>{policy.number}</Typography>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' component='h3'>
                        {policy.number}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

const getPolicyImage = (policy: Policy) => {
    switch (policy.classCode) {
        case 'MV':
        case 'MVC':
        case 'MVN':
        case 'TX': // no longer used
            return <Car />;

        case 'CVN':
            return <Caravan />;

        case 'BU':
        case 'CRI':
        case 'KI':
            return <Handcuffs />;

        case 'FI':
            return <Fire />;

        case 'AV':
        case 'AVN':
        case 'TR':
            return <Plane />;

        case 'THN':
            return <Horse />;

        case 'GL':
            return <Glass />;

        case 'MO':
        case 'BF':
        case 'CR':
        case 'CRN':
            return <Money />;

        case 'STC':
        case 'STR':
        case 'GP':
        case 'HO': // no longer used
            return <House />;

        case 'PI':
        case 'PIN':
        case 'PA':
        case 'DO':
        case 'PL':
        case 'PLN':
            return <Person />;

        case 'CP':
        case 'CYB':
        case 'EIT':
        case 'EE':
            return <Laptop />;

        case 'RD':
        case 'RU':
        case 'RUN':
        case 'CRP':
            return <Tractor />;

        default:
            return <Generic />;
    }
};
