import { Alert, AlertTitle } from '@mui/material';
import moment from 'moment';
import { InvoiceSummary } from '../../apis/invoices';
import { DATE_LONG, DATE_SERVER_FORMAT } from '../../util/dateUtils';

type Props = {
    invoice: InvoiceSummary;
};

export default function CancelledAlert({ invoice }: Readonly<Props>) {
    return (
        <Alert color='warning' variant='outlined' sx={{ py: 2 }}>
            <AlertTitle>Inactive</AlertTitle>
            This insurance was cancelled
            {invoice.cancellationDate &&
                ` on ${moment(invoice.cancellationDate, DATE_SERVER_FORMAT).format(DATE_LONG)}`}
            . No further payments are due.
        </Alert>
    );
}
