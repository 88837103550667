import { Box, Typography } from '@mui/material';
import { common, grey } from '@mui/material/colors';
import { CustomCheckoutType, InvoiceStatus, MigrationStatus } from '../../apis/invoices';
import LoanWithIQumulate from '../../components/LoanWithIQumulate';
import { useAppSelector } from '../../store/reducer/Hooks';
import { RootState } from '../../store/Store';
import DocumentDetails from './DocumentDetails';
import PaymentDetails from './PaymentDetails';
import PolicyDetails from './PolicyDetails';

export default () => {
    const invoice = useAppSelector((state: RootState) => state.persistedInvoiceReducer).value;
    const cancelled = [InvoiceStatus.CANCELLED, InvoiceStatus.PENDING_CANCELLATION].includes(invoice.status);
    const loanWithIQumulate = invoice.customCheckoutDetail?.customCheckoutType === CustomCheckoutType.IQUMULATE;

    return (
        <Box
            sx={{
                py: { xs: 2, sm: 6 },
                px: { xs: 2, sm: 4 },
                borderColor: grey['400'],
                borderRadius: 3,
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
                backgroundColor: common.white,
            }}
        >
            {!loanWithIQumulate && !cancelled && <PaymentDetails />}
            <PolicyDetails />
            {!loanWithIQumulate && invoice.migrationStatus !== MigrationStatus.PARTIAL && <DocumentDetails />}
            {!loanWithIQumulate && (
                <Box sx={{ width: '100%' }}>
                    <Typography variant='subtitle2' component='p' textAlign='center'>
                        Payment ID: {invoice.number}
                    </Typography>
                </Box>
            )}

            {loanWithIQumulate && <LoanWithIQumulate invoice={invoice} showRedirectUrl={true} />}
        </Box>
    );
};
