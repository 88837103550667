import { Alert, AlertTitle } from '@mui/material';
import moment from 'moment';
import { InvoiceSummary, Loan } from '../../apis/invoices';
import { FetchState, isSuccess } from '../../hooks/useFetch';
import { RootState } from '../../store/Store';
import { useAppSelector } from '../../store/reducer/Hooks';
import { DATE_LONG, DATE_SERVER_FORMAT, isInFuture } from '../../util/dateUtils';

type Props = {
    invoice: InvoiceSummary;
};

export default function CancellationScheduledAlert({ invoice }: Readonly<Props>) {
    const { value: loanState } = useAppSelector((state: RootState) => state.LoanReducer);

    if (invoice.nextPayment != null && !isSuccess(loanState)) {
        return null;
    }

    const lastPaymentDate = getLastPaymentDate(invoice, loanState);
    return (
        <Alert color='warning' variant='outlined' sx={{ py: 2 }}>
            <AlertTitle>Cancels on {moment(invoice.cancellationDate, DATE_SERVER_FORMAT).format(DATE_LONG)}</AlertTitle>
            {lastPaymentDate == null
                ? 'No further payment is due.'
                : `Your final scheduled payment will be on ${lastPaymentDate}`}
        </Alert>
    );
}

const getLastPaymentDate = (invoice: InvoiceSummary, loanState: FetchState<Loan>): string | null => {
    if (invoice.nextPayment == null || !isSuccess(loanState)) {
        return null;
    }

    const futurePayments = loanState.value.consolidatedPayments.filter((payment) => isInFuture(payment.dueDate));
    const noOffuturePayments = futurePayments.length;
    if (noOffuturePayments === 0) {
        return null;
    }

    const date = futurePayments[noOffuturePayments - 1].dueDate;
    return moment(date).format(DATE_LONG);
};
