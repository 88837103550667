import { RouteProps } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Header from '../layout/SignInHeader';
import EnterEmailStep from './EnterEmailStep';
import EnterCodeStep from './EnterCodeStep';
import { useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import PageLoading from './PageLoading';
import { Hub } from 'aws-amplify';
import { Box } from '@mui/material';

export type Props = RouteProps & {
    children: JSX.Element;
};

enum Step {
    ENTER_EMAIL,
    ENTER_CODE,
}

export default (props: Props) => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const [step, setStep] = useState(Step.ENTER_EMAIL);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

    const setEmailStepComplete = () => {
        setStep(Step.ENTER_CODE);
    };

    const setEnterCodeStepComplete = () => {
        setStep(Step.ENTER_EMAIL);
    };

    const stepMapper = {
        [Step.ENTER_EMAIL]: <EnterEmailStep stepComplete={setEmailStepComplete} setCognitoUser={setCognitoUser} />,
        [Step.ENTER_CODE]: <EnterCodeStep user={cognitoUser!} stepComplete={setEnterCodeStepComplete} />,
    };

    useEffect(() => {
        return Hub.listen('auth', (data) => {
            if (data.payload.event === 'signOut') {
                setStep(Step.ENTER_EMAIL);
            }
        });
    }, []);

    if (!authStatus || authStatus === 'configuring') {
        return <PageLoading />;
    }

    if (authStatus !== 'authenticated') {
        return (
            <Box sx={{ width: { xs: '346px', sm: '478px' }, margin: 'auto' }}>
                <Header />
                {stepMapper[step]}
            </Box>
        );
    }

    return props.children;
};
