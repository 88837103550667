import { Edit, ErrorOutline } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageLoading from '../../components/PageLoading';
import ResponsiveIconButton from '../../components/ResponsiveIconButton';
import { isSuccess } from '../../hooks/useFetch';
import { useAppSelector } from '../../store/reducer/Hooks';
import ChangePaymentMethod from './ChangePaymentMethod';
import PaymentMethodDetails from './PaymentMethodDetails';

export default function PaymentMethodRow({ canEditPayments }: { canEditPayments: boolean }) {
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('sessionId');

    const { paymentMethodState, availablePaymentMethodsState } = useAppSelector(
        (state) => state.persistedInvoiceReducer
    );

    const closePaymentMethod = () => setOpenPaymentMethod(false);
    const editPaymentMethod = () => setOpenPaymentMethod(true);

    useEffect(() => {
        if (sessionId != null) editPaymentMethod();
    }, [sessionId]);

    if (!isSuccess(paymentMethodState) || !isSuccess(availablePaymentMethodsState)) {
        return <PageLoading />;
    }

    const paymentMethodValid = availablePaymentMethodsState.value
        .map(({ paymentGatewayIdentifier }) => paymentGatewayIdentifier)
        .includes(paymentMethodState.value.paymentGatewayIdentifier);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='subtitle2' component='p'>
                    Payment method
                </Typography>
                {paymentMethodValid && <PaymentMethodDetails data={paymentMethodState.value} />}
                {!paymentMethodValid && (
                    <Grid style={{ display: 'flex' }}>
                        <ErrorOutline color='error' />
                        <Typography variant='subtitle2' color='error' sx={{ ml: 1 }}>
                            No payment method added
                        </Typography>
                    </Grid>
                )}
            </Box>
            <Box>
                <ResponsiveIconButton
                    data-testid='edit-payment-method'
                    icon={<Edit />}
                    onClick={editPaymentMethod}
                    disabled={!canEditPayments}
                    size='small'
                >
                    {paymentMethodValid ? 'Edit' : 'Add'}
                </ResponsiveIconButton>
                <ChangePaymentMethod open={openPaymentMethod} setClosed={closePaymentMethod} sessionId={sessionId} />
            </Box>
        </Box>
    );
}
