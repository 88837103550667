import { RootState } from '../store/Store';
import { BREAKPOINT } from '../store/reducer/BreakpointReducer';
import StandardLayout from './StandardLayout';
import MobileLayout from './MobileLayout';
import { Home, Person, SvgIconComponent } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/reducer/Hooks';
import { clearInvoice } from '../store/reducer/InvoiceReducer';
import { Auth, Hub } from 'aws-amplify';
import { Box } from '@mui/material';
import Footer from './Footer';
import { clearInvoices } from '../store/reducer/InvoicesReducer';
import { clearLoan } from '../store/reducer/LoanReducer';

export default () => {
    const [selectedPage, setSelectedPage] = useState(PageType.Insurance);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        pages.forEach((page) => {
            const match = page.routeRegexes.some((regex) => regex.test(location.pathname));
            if (match) {
                setSelectedPage(page.type);
            }
        });
    }, [location]);

    useEffect(() => {
        Hub.listen('auth', (data) => {
            if (data.payload.event === 'signOut') {
                dispatch(clearInvoice());
                dispatch(clearInvoices());
                dispatch(clearLoan());
                navigate('/');
            }
        });
    }, []);

    const handlePageClick = (page: Page) => {
        setSelectedPage(page.type);
        navigate(page.goto);
    };

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <ScrollRestoration />
            <Box sx={{ flex: 1 }}>
                <Layout selectedPage={selectedPage} handlePageClick={handlePageClick} logout={() => Auth.signOut()}>
                    <Outlet />
                </Layout>
            </Box>
            <Footer />
        </Box>
    );
};

type LayoutProps = {
    children: React.ReactNode;
    selectedPage: PageType;
    handlePageClick: (page: Page) => void;
    logout: () => void;
};

const Layout = ({ selectedPage, handlePageClick, logout, children }: LayoutProps) => {
    const { up } = useAppSelector((state: RootState) => state.BreakpointReducer);
    if (up(BREAKPOINT.sm)) {
        return (
            <StandardLayout selectedPage={selectedPage} handlePageClick={handlePageClick} logout={logout}>
                {children}
            </StandardLayout>
        );
    }

    return (
        <MobileLayout selectedPage={selectedPage} handlePageClick={handlePageClick} logout={logout}>
            {children}
        </MobileLayout>
    );
};

export enum PageType {
    Insurance,
    PersonalInfo,
}

export type Page = {
    type: PageType;
    goto: string;
    routeRegexes: RegExp[];
    name: string;
    icon: SvgIconComponent;
};

export const pages: Page[] = [
    {
        type: PageType.Insurance,
        goto: '/',
        routeRegexes: [/^\/$/, /^\/invoices/],
        name: 'Insurance',
        icon: Home,
    },
    {
        type: PageType.PersonalInfo,
        goto: '/my-profile',
        routeRegexes: [/^\/my-profile$/],
        name: 'Personal Info',
        icon: Person,
    },
];

export const allowBack = [/^\/invoices/];
