import axios from 'axios';
import { InvoiceSummary, Loan } from './invoices';

export enum PaymentStatus {
    SUCCEED = 'SUCCEED',
    PROCESSING = 'PROCESSING',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    INITIATED = 'INITIATED',
    READY_FOR_BILLING = 'READY_FOR_BILLING',
    ERROR = 'ERROR',
}

type BasePaymentResponse = {
    status: PaymentStatus;
    uuid: string;
};

type SuccessfulPaymentResponse = BasePaymentResponse & {
    loan: Loan;
    invoice: InvoiceSummary;
};

export type PaymentResponse = BasePaymentResponse | SuccessfulPaymentResponse;

export const makeOnDemandPayment = async (invoiceIdentifier: string): Promise<PaymentResponse> => {
    const url = new URL(`${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/payment/on-demand`);
    return await axios.post(url.href).then(({ data }) => data);
};

export const queryPaymentResult = async (
    invoiceIdentifier: string,
    paymentIdentifier: string
): Promise<PaymentResponse> => {
    const url = new URL(
        `${process.env.REACT_APP_CLIENT_PORTAL_HOST}/invoices/${invoiceIdentifier}/payment/${paymentIdentifier}`
    );
    return await axios.get(url.href).then(({ data }) => data);
};

export const isSuccessfulPaymentResponse = (
    toBeDetermined?: PaymentResponse
): toBeDetermined is SuccessfulPaymentResponse => {
    return toBeDetermined?.status === PaymentStatus.SUCCEED;
};
