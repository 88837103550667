import { grey } from '@mui/material/colors';

type FlexWindow = Window & {
    Flex: {
        new (paymentSession: string): Flex;
    };
};

type Flex = {
    microform: (options: { styles: Record<string, unknown> }) => Microform;
};

export type Microform = {
    createField: (fieldName: string, options: Record<string, unknown>) => Field;
    createToken: (options: Record<string, unknown>, callback: (err: Error, token: string) => void) => void;
};

type Error = {
    message: string;
    details?: {
        location: string;
    }[];
};

type Field = {
    load: (id: string) => void;
    on: (action: string, method: (data: FieldData) => void) => void;
    update: (options: Record<string, unknown>) => void;
};

type FieldData = {
    empty: boolean;
    valid: boolean;
    couldBeValid: boolean;
    card: {
        securityCode: {
            name: string;
        };
    }[];
};

export default ({
    paymentSession,
    setCardNumberError,
    setSecurityCodeError,
    setMicroForm,
    setLoadingCybersourceInputs,
}: {
    paymentSession: string;
    setCardNumberError: (error: string | null) => void;
    setSecurityCodeError: (error: string | null) => void;
    setMicroForm: (microform: Microform) => void;
    setLoadingCybersourceInputs: (loading: boolean) => void;
}) => {
    const styles = {
        input: {
            'font-size': '16px',
            'font-family': 'Montserrat, Helvetica, Arial, sans-serif',
            color: grey['500'],
        },
        ':disabled': { cursor: 'not-allowed' },
    };
    const flex = new (window as unknown as FlexWindow).Flex(paymentSession);
    const microformSetup = flex.microform({ styles });

    const number = microformSetup.createField('number', {});
    number.load('#number-container');
    number.on('change', (data) => {
        if (data.empty) {
            setCardNumberError('Card number required');
            return;
        }

        if (!data.valid && !data.couldBeValid) {
            setCardNumberError('Invalid card number');
            return;
        } else {
            setCardNumberError(null);
        }

        if (data.card[0]) {
            securityCode.update({ disabled: false, placeholder: data.card[0].securityCode.name });
        }
    });

    const securityCode = microformSetup.createField('securityCode', { placeholder: '***' });
    securityCode.load('#securityCode-container');

    securityCode.on('change', (data) => {
        if (!data.valid && !data.couldBeValid) {
            setSecurityCodeError('Invalid code');
        } else {
            setSecurityCodeError(null);
        }

        if (data.empty) {
            setSecurityCodeError('Code required');
        }
    });

    setMicroForm(microformSetup);
    setLoadingCybersourceInputs(false);
};
