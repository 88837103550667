import axios from 'axios';

export type BaseClient = {
    uuid: string;
    identifier: string;
    createdDate: string;
    contactDetails: ContactDetails;
    insuredType: InsuredType;
    displayName: string;
};

export enum InsuredType {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS',
    TRUST = 'TRUST',
}

export type IndividualClient = BaseClient & {
    personalDetails: PersonalDetails;
};

export type AuthorizedIndividual = {
    personalDetails: Pick<PersonalDetails, 'givenName' | 'surname'>;
    contactDetails: Pick<ContactDetails, 'email' | 'preferredPhoneNumber'>;
};

export enum BusinessEntityType {
    SOLE_TRADER = 'SOLE_TRADER',
    LIMITED_LIABILITY = 'LIMITED_LIABILITY',
    REGISTERED_COMPANY = 'REGISTERED_COMPANY',
}

export type BusinessClient = BaseClient & {
    companiesOfficeNumber: string;
    businessNumber: string;
    entityType: BusinessEntityType;
    businessName: string;
    tradingName?: string;
    authorizedIndividual: AuthorizedIndividual;
};

export type TrustClient = BaseClient & {
    businessNumber: string;
    trustName: string;
    authorizedIndividual: AuthorizedIndividual;
};

export type Client = IndividualClient | BusinessClient | TrustClient;

export type ContactDetails = {
    email: Email;
    physicalAddress: Address;
    preferredPhoneNumber: PhoneNumber;
};

export type PersonalDetails = {
    title?: string | null;
    givenName: string;
    surname: string;
    middleName?: string | null;
    gender?: string | null;
    dateOfBirth?: string;
};

export type Address = {
    addressLine1: string;
    addressLine2?: string | null;
    suburb: string;
    city: string;
    state?: string | null;
    postCode: string;
    country: string;
    type?: string | null;
};

export type PhoneNumber = {
    number: string;
    dialingCode?: string | null;
};

export type Email = {
    address: string;
    emailType?: string | null;
};

export const fetchMyProfile = async (): Promise<Client> => {
    return await axios.get(process.env.REACT_APP_CLIENT_PORTAL_HOST + '/my-profile').then(({ data }) => data);
};

// type guards

export const isIndividual = (toBeDetermined?: Client): toBeDetermined is IndividualClient => {
    return toBeDetermined?.insuredType === InsuredType.INDIVIDUAL;
};

export const isBusiness = (toBeDetermined?: Client): toBeDetermined is BusinessClient => {
    return toBeDetermined?.insuredType === InsuredType.BUSINESS;
};

export const isTrust = (toBeDetermined?: Client): toBeDetermined is TrustClient => {
    return toBeDetermined?.insuredType === InsuredType.TRUST;
};
