import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../store/reducer/Hooks';
import { RootState } from '../../store/Store';
import { InvoiceStatus, PaymentFrequency } from '../../apis/invoices';
import { downloadDirectDebitAuthority, downloadLoanContract } from '../../apis/document';
import { PaymentMethodType } from '../../apis/paymentMethodApi';
import { isSuccess } from '../../hooks/useFetch';
import DocumentItem from './DocumentItem';

export default function DocumentDetails() {
    const { value: invoice, paymentMethodState } = useAppSelector((state: RootState) => state.persistedInvoiceReducer);
    const { value: loanState } = useAppSelector((state: RootState) => state.LoanReducer);

    const hasLoanContractFile =
        invoice.status === InvoiceStatus.PAID &&
        invoice.paymentFrequency !== PaymentFrequency.IN_FULL &&
        isSuccess(loanState) &&
        loanState.value.annualInterestRate > 0;
    const hasDirectDebitAuthorityFile =
        isSuccess(paymentMethodState) && paymentMethodState.value?.type === PaymentMethodType.DIRECT_DEBIT;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant='h5' component='h2'>
                Documents
            </Typography>
            {!hasLoanContractFile && !hasDirectDebitAuthorityFile && (
                <Typography variant='body1'>There are no documents available</Typography>
            )}
            {hasLoanContractFile && (
                <DocumentItem category='Loan Contract' invoice={invoice} fileDownloader={downloadLoanContract} />
            )}
            {hasDirectDebitAuthorityFile && (
                <DocumentItem
                    category='Direct Debit Authority'
                    invoice={invoice}
                    fileDownloader={downloadDirectDebitAuthority}
                />
            )}
        </Box>
    );
}
