import { Alert, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import {
    Adjustment,
    AdjustmentOverview,
    AdjustmentResult,
    Loan,
    PaymentFrequency,
    confirmAdjustment,
} from '../../../apis/invoices';
import StepCard from '../../../components/StepCard';
import { capitalize } from 'lodash';
import moment from 'moment';
import { DATE_CARDINAL, DATE_SERVER_FORMAT, DAY_OF_WEEK } from '../../../util/dateUtils';
import { useState } from 'react';
import { FetchState, FetchStateType, isError, isLoading } from '../../../hooks/useFetch';
import { LoadingButton } from '../../../components/LoadingButton';
import { useAppSelector } from '../../../store/reducer/Hooks';

type Props = {
    adjustmentOverview: AdjustmentOverview;
    cancel: () => void;
    handleAdjustmentResult: (adjustment: AdjustmentResult) => void;
};

export default ({ adjustmentOverview, cancel, handleAdjustmentResult }: Props) => {
    const { value: invoice } = useAppSelector((root) => root.persistedInvoiceReducer);
    const [confirmationState, setConfirmationState] = useState<FetchState<Loan>>({ type: FetchStateType.PENDING });
    const endOfMonthHint = getEndOfMonthHint(adjustmentOverview.adjusted);

    const handleConfirmation = () => {
        setConfirmationState({ type: FetchStateType.LOADING });
        confirmAdjustment(
            invoice.uuid,
            adjustmentOverview.adjusted.paymentFrequency,
            adjustmentOverview.adjusted.nextPaymentDate
        )
            .then(handleAdjustmentResult)
            .catch((error) => setConfirmationState({ type: FetchStateType.ERROR, error }));
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Review changes
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ maxWidth: '33%' }}>Current</TableCell>
                        <TableCell sx={{ maxWidth: '33%' }}>New</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map(({ rowName, getValue }) => (
                        <TableRow key={rowName}>
                            <TableCell>{rowName}</TableCell>
                            <TableCell sx={{ maxWidth: '33%' }}>{getValue(adjustmentOverview.current)}</TableCell>
                            <TableCell sx={{ maxWidth: '33%', fontWeight: 500 }}>
                                {getValue(adjustmentOverview.adjusted, endOfMonthHint != null)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {endOfMonthHint != null && <Typography variant='caption'>{endOfMonthHint}</Typography>}
            {isError(confirmationState) && (
                <Alert color='error' severity='error'>
                    Something went wrong, please try again.
                </Alert>
            )}
            <Box sx={{ display: 'flex', gap: 1 }}>
                <LoadingButton
                    sx={{ width: { sm: '230px' } }}
                    loading={isLoading(confirmationState)}
                    variant='contained'
                    onClick={handleConfirmation}
                    type='submit'
                >
                    Confirm
                </LoadingButton>
                <Button variant='text' onClick={cancel}>
                    Cancel
                </Button>
            </Box>
        </StepCard>
    );
};

const getEndOfMonthHint = (adjustment: Adjustment) => {
    const nextPaymentDate = moment(adjustment.nextPaymentDate, DATE_SERVER_FORMAT);
    const dayOfMonth = nextPaymentDate.date();

    if (adjustment.paymentFrequency === PaymentFrequency.MONTHLY && dayOfMonth > 28) {
        return `* Or the last day of the month for months with less than ${dayOfMonth} days`;
    }
};

type TableData = {
    rowName: string;
    getValue: (adjustment: Adjustment, addStarToDay?: boolean) => JSX.Element | string;
    hide?: (adjustment: Adjustment) => boolean;
};

const tableData: TableData[] = [
    {
        rowName: 'Frequency',
        getValue: (adjustment) => capitalize(adjustment.paymentFrequency),
    },
    {
        rowName: 'Day',
        getValue: (adjustment, addStarToDay) => {
            const nextPaymentDate = moment(adjustment.nextPaymentDate, DATE_SERVER_FORMAT);

            if (adjustment.paymentFrequency === PaymentFrequency.MONTHLY) {
                if (addStarToDay) {
                    return nextPaymentDate.format(DATE_CARDINAL) + '*';
                }

                return nextPaymentDate.format(DATE_CARDINAL);
            }

            return nextPaymentDate.format(DAY_OF_WEEK) + 's';
        },
    },
    {
        rowName: 'Next Payment Amount',
        getValue: (adjustment) =>
            currencyFormatter.format(
                adjustment.termPayment.nextInstalmentAmount + adjustment.termPayment.nextInstalmentFeeAmount
            ),
    },
    {
        rowName: 'Regular Payment Amount',
        getValue: (adjustment) =>
            currencyFormatter.format(
                adjustment.termPayment.regularInstalmentAmount + adjustment.termPayment.regularInstalmentFeeAmount
            ),
        hide: (adjustment) => {
            const nextPayment =
                adjustment.termPayment.nextInstalmentAmount + adjustment.termPayment.nextInstalmentFeeAmount;
            const regularPayment =
                adjustment.termPayment.regularInstalmentAmount + adjustment.termPayment.regularInstalmentFeeAmount;
            return nextPayment == regularPayment;
        },
    },
];

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
