import { Box } from '@mui/material';
import Logo from './Logo';

export default () => {
    return (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <Logo width='200px' />
        </Box>
    );
};
