import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import CybersourceReducer from './reducer/CybersourceReducer';
import BreakpointReducer from './reducer/BreakpointReducer';
import InvoicesReducer from './reducer/InvoicesReducer';
import { configureStore } from '@reduxjs/toolkit';
import InvoiceReducer from './reducer/InvoiceReducer';
import LoanReducer from './reducer/LoanReducer';
import SellerReducer from './reducer/SellerReducer';

const invoiceConfig = {
    key: 'invoice',
    storage,
};

const persistedInvoiceReducer = persistReducer(invoiceConfig, InvoiceReducer);

const sellerConfig = {
    key: 'seller',
    storage,
};

const persistedSellerReducer = persistReducer(sellerConfig, SellerReducer);

export const Store = configureStore({
    reducer: {
        CybersourceReducer,
        BreakpointReducer,
        InvoicesReducer,
        LoanReducer,
        persistedInvoiceReducer,
        persistedSellerReducer,
    },
    middleware: [thunk],
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

export const persistor = persistStore(Store);
