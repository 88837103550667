import { Address } from '../apis/myProfile';

type Props = {
    address: Address;
};

export default (props: Props) => {
    const address = props.address;

    return (
        <>
            {address.addressLine1}
            {address.addressLine2 && <NewLineComma />}
            {address.addressLine2}
            {address.suburb && <NewLineComma />}
            {address.suburb}
            {(address.city || address.postCode) && <NewLineComma />}
            {address.city} {address.postCode}
        </>
    );
};

const NewLineComma = () => {
    return (
        <>
            ,<br />
        </>
    );
};
