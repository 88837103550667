import { Alert, AlertTitle } from '@mui/material';
import { InvoiceSummary } from '../../apis/invoices';
import { getPaymentAmount } from '../../util/invoiceUtils';

type Props = {
    invoice: InvoiceSummary;
};

export default function OverdueTile({ invoice }: Readonly<Props>) {
    return (
        <Alert variant='outlined' severity='error' sx={{ width: '100%' }}>
            <AlertTitle>{currencyFormatter.format(getPaymentAmount(invoice))} Overdue</AlertTitle>
            Your recent automatic payment has failed.
        </Alert>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
