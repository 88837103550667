import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/system';

const PageLoading = () => {
    return (
        <Typography style={{ textAlign: 'center', paddingTop: '20px' }} data-testid='loading'>
            <SimfuniCircularProgress size={40} />
        </Typography>
    );
};

export default PageLoading;

const SimfuniCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary?.dark,
}));
