import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, View, Alert, Flex, PasswordField } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Box, Link, Typography } from '@mui/material';
import SimfuniAuthCard from './SimfuniAuthCard';
import { includes } from 'lodash';
import { AxiosError } from 'axios';

export type Props = {
    user: CognitoUser;
    stepComplete: () => void;
};

export enum ErrorType {
    WARNING = 'warning',
    ERROR = 'error',
}

export type AuthError = {
    type: ErrorType;
    message: JSX.Element;
};

export default (props: Props) => {
    const [otpCode, setOtpCode] = useState('');
    const [isLoadingState, setIsLoadingState] = useState(false);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
    const [error, setError] = useState<AuthError | null>(null);
    const [isTerminalState, setIsTerminalState] = useState(false);

    useEffect(() => {
        setCognitoUser(props.user);
    }, [props.user]);

    const submitHandler = (e: SyntheticEvent) => {
        setIsLoadingState(true);
        setError(null);

        Auth.sendCustomChallengeAnswer(cognitoUser, otpCode)
            .then((challengeResult) => {
                if (challengeResult.challengeName) {
                    const attemptsRem = +challengeResult.challengeParam.attemptsLeft;
                    setOtpCode('');
                    setError({
                        type: ErrorType.WARNING,
                        message: <>{`Invalid code, ${attemptsRem} attempt${attemptsRem === 1 ? '' : 's'} left.`}</>,
                    });
                }
            })
            .catch(handleSubmitError)
            .finally(() => setIsLoadingState(false));

        e.preventDefault();
    };

    const handleSubmitError = (error: AxiosError) => {
        handleRateLimitError(error);
        if (error.code === 'NotAuthorizedException') {
            setError({
                type: ErrorType.ERROR,
                message: (
                    <Box sx={{ display: 'flex' }}>
                        <span>You have exceeded the number of code verification attempts</span>
                        {goBackButton()}
                    </Box>
                ),
            });
        }
    };

    const handleRateLimitError = (error: AxiosError) => {
        if (error.code === 'UserLambdaValidationException' && includes(error.message, 'RATE_LIMITED')) {
            setError({
                type: ErrorType.ERROR,
                message: <>Attempt limit exceeded, please try after some time.</>,
            });
            setIsTerminalState(true);
        }
    };

    const handleOtpCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOtpCode(event.currentTarget.value);
    };

    const goBackButton = () => (
        <Link
            component='button'
            type='button'
            data-testid='goBack'
            onClick={() => props.stepComplete()}
            sx={{ cursor: 'pointer', textDecoration: 'none', paddingLeft: 1, paddingRight: 2, textWrap: 'nowrap' }}
        >
            Go back
        </Link>
    );

    const resendCode = () => {
        setIsLoadingState(true);
        Auth.signIn(props.user.getUsername())
            .then(setCognitoUser)
            .catch(handleRateLimitError)
            .finally(() => setIsLoadingState(false));
    };

    return (
        <SimfuniAuthCard>
            <>
                <form onSubmit={submitHandler} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Typography variant='h3' component='h1'>
                        We've sent you a one-time code
                    </Typography>
                    <Typography>A code has been sent to {props.user.getUsername()}</Typography>
                    <Flex gap='0' alignItems='center'>
                        <Typography>Not the right email? </Typography>
                        {goBackButton()}
                    </Flex>
                    <PasswordField data-testid='passcode' label='Code' onChange={handleOtpCodeChange} value={otpCode} />
                    {error && (
                        <Alert data-testid='error' hasIcon variation={error.type}>
                            {error.message}
                        </Alert>
                    )}
                    <Button
                        variation='primary'
                        data-testid='submit'
                        type='submit'
                        isFullWidth
                        isLoading={isLoadingState}
                        loadingText='Submit'
                        isDisabled={isTerminalState}
                    >
                        Submit
                    </Button>
                </form>
                {!isTerminalState && (
                    <View textAlign='center'>
                        <Button fontWeight='normal' onClick={resendCode} size='small' variation='link'>
                            Resend code
                        </Button>
                    </View>
                )}
            </>
        </SimfuniAuthCard>
    );
};
