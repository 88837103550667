import { Box, Button, List } from '@mui/material';
import { Page, PageType, allowBack, pages } from '.';
import { Logout } from '@mui/icons-material';
import PageButton from './PageButton';

import BackButton from '../components/BackButton';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';

type Props = {
    children: React.ReactNode;
    selectedPage: PageType;
    handlePageClick: (page: Page) => void;
    logout: () => void;
};

export default ({ children, selectedPage, handlePageClick, logout }: Props) => {
    const location = useLocation();
    const canGoBack = allowBack.some((regex) => regex.test(location.pathname));

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 256px' }} component='nav'>
                <Box sx={{ mx: 2, mt: 4, mb: 6 }}>
                    <Logo width='115px' />
                </Box>
                <List sx={{ mx: 1 }}>
                    {pages.map((page) => (
                        <PageButton
                            key={page.type}
                            page={page}
                            selectedPage={selectedPage}
                            handlePageClick={handlePageClick}
                        />
                    ))}
                </List>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', m: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 6 }}>
                    <Box>{canGoBack && <BackButton />}</Box>
                    <Box>
                        <Button endIcon={<Logout />} color='secondary' onClick={logout} data-testid='logout-button'>
                            Log out
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ maxWidth: '725px' }} component='main'>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};
