import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { useState } from 'react';
import { InvoiceSummary } from '../../../apis/invoices';
import PageLoading from '../../../components/PageLoading';
import { isSuccess } from '../../../hooks/useFetch';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { getPaymentAmount } from '../../../util/invoiceUtils';
import MakePayment from './MakePayment';

type Props = {
    invoice: InvoiceSummary;
};

export default function PaymentRequiredAlert({ invoice }: Readonly<Props>) {
    const [openMakePayment, setOpenMakePayment] = useState(false);
    const paymentAmount = getPaymentAmount(invoice);

    const { paymentMethodState, availablePaymentMethodsState } = useAppSelector(
        (state) => state.persistedInvoiceReducer
    );

    if (!isSuccess(paymentMethodState) || !isSuccess(availablePaymentMethodsState)) {
        return <PageLoading />;
    }

    const paymentMethodUpdated = availablePaymentMethodsState.value
        .map(({ paymentGatewayIdentifier }) => paymentGatewayIdentifier)
        .includes(paymentMethodState.value.paymentGatewayIdentifier);

    return (
        <Alert color='warning' variant='outlined' sx={{ py: 2 }}>
            <AlertTitle>{currencyFormatter.format(paymentAmount)} outstanding</AlertTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <span>
                    Your application to pay over time was declined. The full remaining balance of{' '}
                    {currencyFormatter.format(paymentAmount)} is due now.
                    {!paymentMethodUpdated && ' Add a payment method below, then make payment.'}
                </span>
                {paymentMethodUpdated && (
                    <Button
                        sx={{ width: '228px' }}
                        color='warning'
                        variant='contained'
                        onClick={() => setOpenMakePayment(true)}
                    >
                        Make a payment
                    </Button>
                )}
                <MakePayment
                    open={openMakePayment}
                    setClosed={() => setOpenMakePayment(false)}
                    title='Pay outstanding balance'
                    description='Due now'
                />
            </Box>
        </Alert>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
